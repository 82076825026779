import { useBreakpointValue } from '@gluestack-style/react';
import { Box, View } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'utils/platform';


import Button from 'components/Button';
import Card from 'components/Card';
import DropDown from 'components/DropDown';
import FocusView from 'components/FocusView';
import Grid from 'components/Grid';
import TvSeasonsMenu from 'components/TvSeasonsMenu/TvSeasonsMenu';
import IsLoading from 'components/isLoading';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { calcSizeForDevice, calcSizeForTv, hourFormatterBySeconds } from 'utils/index';

import { useProgram } from './context';
import { useEnvironment } from 'hooks/useEnvironment';

type ProgramEpisodesProps = {
  seasons: CmsProgram['seasons'];
  episodes?: CmsApiMediaList;
  setEpisode: (episode: CmsApiItem<CmsApiMediaList>) => void;
  setMediaId: (mediaId: string) => void;
  setSelected: (s: string) => void;
  selected?: string;
  actual: any;
  onEndReached?: () => void;
  isLoading?: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const ProgramEpisodes: React.FC<ProgramEpisodesProps> = ({
  actual,
  seasons,
  episodes,
  setMediaId,
  setEpisode,
  setSelected,
  onEndReached,
  isLoading,
  setIsLoading,
  selected,
}) => {
  const navigation = useNavigation();
  const [defaultSelected, setDefaultSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const program = useProgram();
  const { deeplinkUrl } = useEnvironment();

  useEffect(() => {
    setLoading(true);

    if (!!actual?.id && !!seasons.length) {
      const seasonIndex = Math.max(
        seasons.findIndex((s) => s?.id == actual.id),
        0
      );

      if (seasonIndex) {
        setDefaultSelected(seasonIndex);
      } else {
        setLoading(false);
      }
    }

    setLoading(false);
  }, [actual]);

  useEffect(() => {
    setLoading(false);
  }, [defaultSelected]);

  const numCols = useBreakpointValue({
    md: Platform.isAndroidTV ? 3 : 2,
    sm: 1,
    lg: 3,
    xl: Platform.isTV ? 3 : 4,
    base: 1,
  });

  const gap = useBreakpointValue({
    md: 8,
    sm: 8,
    lg: 16,
    xl: 16,
    base: 8,
  });

  const onTvMenuTabPress = (selectedValue: string) => {
    if (selectedValue !== actual?.id) {
      setSelected(selectedValue);
    }
  };

  const renderSeasonItem = ({ item }: any) => {
    return (
      <Button
        useTVFocus
        variant="solid"
        backgroundColor="transparent"
        style={{
          marginRight: calcSizeForTv(20),
          padding: calcSizeForTv(25),
          borderWidth: calcSizeForTv(3),
          borderBottomWidth: calcSizeForTv(3),
          borderColor: actual?._id === item?.id ? 'white' : 'transparent',
        }}
        textTransform="uppercase"
        textStyle={{
          fontSize: calcSizeForTv(20),
          lineHeight: calcSizeForTv(24),
          color: actual?.id === item?.id ? 'white' : '#7A8CFF',
          textShadowColor: 'rgba(7, 13, 49, 0.7)',
          textShadowOffset: { width: 1, height: 1 },
        }}
        onPress={() => onTvMenuTabPress(item.id)}>
        {item.name}
      </Button>
    );
  };

  const renderItem = (item: CmsApiItem<CmsApiMediaList>) => (
    <Card
      type="midBoxed"
      title={item.title}
      duration={hourFormatterBySeconds(item.duration || 0)}
      description={item?.summary}
      uri={item.imageHorizontal?.url!}
      lines={{ title: 2, description: Platform.isTV ? 4 : 3 }}
      onPress={() => {
        const title = item?.title.replaceAll('-', '').replaceAll('/', '-').replaceAll(' ', '-');

        if (Platform.OS === 'web') {
          window.history.pushState(
            null,
            null,
            `${deeplinkUrl}/${program.id}/${actual.id}/${title}/${item?.mediaId}`
          );
        }

        setEpisode(item);
        setMediaId(item?.mediaId);
      }}
    />
  );

  if (!actual || !episodes?.items || loading) {
    return null;
  }

  return (
    <View gap="$4" backgroundColor={Platform.OS !== 'web' ? theme.colors.base.darkBlue : ''}>
      {Platform.isTV ? (
        <TvSeasonsMenu items={seasons} renderItem={renderSeasonItem} />
      ) : (
        <View mx="$2">
          <DropDown
            items={
              seasons?.map((season) => ({
                id: season._id,
                value: season._id,
                label: season.name,
              })) || []
            }
            defaultSelectedItem={defaultSelected}
            onChangeSelectedItem={(item) => {
              navigation.setParams({ episodeName: '', seasonId: '', mediaId: '' } as any);
              setTimeout(() => {
                setSelected(item.value);
              }, 500);
            }}
          />
        </View>
      )}

      {isLoading ? (
        <Box alignItems="center" justifyContent="center" height={calcSizeForDevice(300)}>
          <IsLoading />
        </Box>
      ) : (
        <Grid
          onEndReached={onEndReached}
          gap={gap}
          key={numCols}
          numColumns={numCols}
          data={episodes.items!}
          renderItem={renderItem}
        />
      )}
    </View>
  );
};

export default ProgramEpisodes;

import { Box, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useSocket } from 'context/SocketContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFirebaseAnalytics from 'services/analytics';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import Hero from 'components/Hero';
import Player from 'components/Player';
import { useStream } from 'components/Player/Stream/context';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramHeader from 'components/Program/Header';
import ProgramRelated from 'components/Program/Related';
import ProgramSave from 'components/Program/Save';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';
import { isMobileWeb } from 'utils/deviceInfo';

const MovieScreen: React.FC<RootStackScreenProps<'Program'>> = ({ navigation }) => {
  const program = useProgram();
  const [mediaId, setMediaId] = useState<string>();
  const { isPlaying, isVisible, isFullScreen, isStreaming, pause, play, setIsVisible } =
    usePlayer();
  const [isActive, setIsActive] = useState(false);
  const { logEventAction } = useFirebaseAnalytics();
  const [startTime, setStartTime] = useState<number>();
  const { watch } = useSocket();
  const { ads } = useGlobalConfig();
  const { isConnected } = useStream();

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) {
        pause();
      }
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);
      return () => {
        if (!isStreaming) {
          pause();
        }
        setIsActive(false);
      };
    }, [isStreaming])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    if (!program) {
      return undefined;
    }

    const media: PlayerMedia = {
      title: program.title,
      image: [program.imageVertical?.url!, program.imageHorizontal?.url!],
      duration: program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: program.mediaId });

    return {
      media,
      isLive: program.type == ProgramType.LIVE || program.type == ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
            /iu=([^&]+)/,
            (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
          ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId, isConnected]);

  useEffect(() => {
    if (!playerProps || playerProps.isLive) {
      return;
    }

    watch(playerProps).then((data) => {
      if (data) {
        setStartTime(data.watchedTime);
      }
    });
  }, [playerProps]);

  const clickWatchObject = {
    component: 'Botão assistir',
    reference: 'Assistir',
    adUnit:
      ads?.disableAds || program.ads?.disableAds
        ? null
        : { name: program.ads?.adunit?.name, value: program.ads?.adunit?.val },
  };

  const Video = useMemo(
    () =>
      mediaId != undefined && isActive && playerProps != undefined ? (
        <Player {...playerProps} startTime={startTime} autoplay />
      ) : undefined,
    [mediaId, isActive, playerProps]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile != undefined && (
        <View style={{ marginTop: isFullScreen ? 0 : isMobileWeb() ? 45 : 80 }}>{Video}</View>
      )}
      {(Platform.isAndroidTV || !isFullScreen) && (
        <Hero program={program} hideCover={mediaId != undefined}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box aspectRatio={1 / 0.56} width={Platform.isTV ? 0 : '80%'}>
                  {Video}
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            <Button
              id="PlayBtn"
              useTVFocus
              hasTVPreferredFocus
              style={{
                ...(Platform.OS === 'web' || Platform.isTV
                  ? {
                    width: calcSizeForDevice(300, 400),
                    display: mediaId && !Platform.isTV ? 'none' : undefined,
                    height: calcSizeForDevice(40, 52),
                  }
                  : { display: mediaId && !Platform.isTV ? 'none' : undefined }),
              }}
              textStyle={{ fontSize: calcSizeForDevice(18, 24) }}
              iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
              iconType={Platform.OS !== 'web' && !Platform.isTV ? 'Play' : undefined}
              variant="gradient"
              size="md"
              isFullWidth={Platform.OS !== 'web' && !Platform.isTV}
              onPress={() => {
                logEventAction(clickWatchObject);
                if (mediaId) {
                  play();
                } else {
                  setMediaId(program?.mediaId);
                }

                if (Platform.isAndroidTV) setIsVisible(true);
              }}>
              {startTime
                ? 'Continuar assistindo'
                : Platform.OS !== 'web'
                  ? 'Assistir'
                  : 'Assistir agora'}
            </Button>
            <HStack
              alignItems="center"
              space="md"
              marginTop={calcSizeForDevice(10, 14)}
              marginBottom={calcSizeForDevice(10, 20)}>
              <ProgramSave />
              <ProgramShared />
            </HStack>
            <ProgramContent />
          </Hero.Header>

          <View py={calcSizeForDevice(24)}>
            <ProgramRelated />
          </View>
        </Hero>
      )}
    </>
  );
};

export default MovieScreen;

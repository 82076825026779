/* eslint-disable import/order */
import { Box } from '@gluestack-ui/themed';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Animated, FlatList, useWindowDimensions } from 'react-native';
import { useGetHighlightsQuery } from 'services/cms';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import CarouselIndicators from 'components/Carousel/CarouselIndicators';
import CarouselItem from 'components/Carousel/CarouselItem';
import FocusView from 'components/FocusView';

const Carousel: React.FC<CmsHomeHero> = (props) => {
  const flatListRef = useRef<FlatList | null>(null);
  const scrollX = useRef(new Animated.Value(0)).current;

  const { data, isLoading } = useGetHighlightsQuery(props.searchPrograms, {
    pollingInterval: 900000,
  });

  const [currentIndex, setCurrentIndex] = useState(1);
  const [scrollInterval, setScrollInterval] = useState<any>();

  const { width: screenWidth, height: rawScreenHeight } = useWindowDimensions();
  const screenHeight = rawScreenHeight > 748 ? 748 : rawScreenHeight;
  const halfScreenHeight = screenHeight / 2;

  const scrollEventConfig = [{ nativeEvent: { contentOffset: { x: scrollX } } }];
  const onScroll = Animated.event(scrollEventConfig, { useNativeDriver: false });

  const MemoizedCarouselItem = memo(({ item, loading, link }: any) => (
    <Box flex={1} w={screenWidth} h={calcSizeForDevice(screenHeight) + 100}>
      <CarouselItem item={item} loading={loading} link={link} />
    </Box>
  ));

  useEffect(() => {
    if (data?.items.length) {
      flatListRef.current?.scrollToIndex({ index: 0, animated: true });
      scrollX.setValue(0);
    }
  }, [data, scrollX]);

  const scrollToItem = (n: number) => {
    flatListRef.current?.scrollToIndex({ animated: true, index: n });
    setCurrentIndex(n + 1);
    clearInterval(scrollInterval);
  };

  if (!data?.items.length) {
    return null;
  }

  return (
    <FocusView>
      <Box
        height={calcSizeForDevice(screenHeight) + 100}
        mb={calcSizeForDevice(14)}
        style={{ marginBottom: -(calcSizeForDevice(halfScreenHeight) - 50) }}>
        <FlatList
          horizontal
          bounces
          pagingEnabled
          ref={flatListRef}
          data={data?.items}
          onScroll={onScroll}
          scrollEnabled={!Platform.isAndroidTV}
          renderItem={({ item }) => (
            <MemoizedCarouselItem item={item} loading={isLoading} link={item.more?.link} />
          )}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ marginBottom: 0 }}
          keyExtractor={(item, index) => `${item.title}_${index}`}
          viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
        />

        <Box
          height="$0"
          position="absolute"
          bottom={calcSizeForDevice(halfScreenHeight) + 70}
          left={Platform.isAndroidTV ? 20 : calcSizeForDevice(24)}>
          <CarouselIndicators
            data={data?.items ?? []}
            scrollX={scrollX}
            scrollToItem={scrollToItem}
          />
        </Box>
      </Box>
    </FocusView>
  );
};

export default Carousel;

import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetFavoritesQuery } from 'services/backend';
import { RootState } from 'store/index';
import { Platform } from 'utils/platform';

import Section from '.';
import EmptyFavorites from './components/EmptyFavorites';

import IsLoading from 'components/isLoading';

export const SectionFavorite = (props: { topContainerHeight?: number | undefined }) => {
  const { data, isLoading } = useGetFavoritesQuery();
  const profile = useSelector((state: RootState) => state.profile);

  const items = React.useMemo(() => {
    if (!data) return [];

    const formattedItems = data.items.map((item) => ({
      title: item.title,
      link: `/${item.url}`,
      image: { url: item.image },
      isKid: item.kids,
    }));

    if (profile.isKid) return formattedItems.filter((item) => item.isKid);
    return formattedItems;
  }, [data]);

  if (isLoading) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </Box>
    );
  }

  if (Platform.isTV && items.length === 0) return <EmptyFavorites />;

  return (
    <Section
      requestTVFocus
      vertical
      items={items}
      _name="section"
      title="Favorites"
      showTitle={false}
      variant="section.medium"
      {...props}
    />
  );
};

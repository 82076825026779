import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import {
  setCleanObject,
  setScreenAnalytics,
  setScreenAnalyticsClientId,
} from 'store/slices/screenAnalyticsSlice';
import { Platform } from 'utils/platform';

import { ComponentEventType, EventsTypesEnum, PageviewType } from './types';
import { useAnalyticsDriver } from './useAnalyticsDriver';

const useFirebaseAnalytics = () => {
  const dispatch = useDispatch();
  const analytics = useAnalyticsDriver();
  const user = useSelector((state: RootState) => state.auth);
  const defaultScreenObject = useSelector((state: RootState) => state.screenAnalytics);
  const platform = Platform.isTV ? 'ctv' : Platform.OS;

  function getPageLocation(pageName: string) {
    return `https://bandplay.com/${pageName}`;
  }

  function buildComponentEventObject(
    params: ComponentEventType,
    screenObject?: Record<string, unknown>
  ) {
    return {
      action: 'clique',
      target: params.target,
      client_id: user.id,
      user_id: user.bandId,
      component: params.component,
      reference: params.reference,
      adUnit: params.adUnit,
      platform,
      ...(screenObject || defaultScreenObject),
    };
  }

  function buildPageEventObject(params: PageviewType, screenObject?: Record<string, unknown>) {
    const { page_location, ...otherParams } = params;
    const formattedPageLocation = page_location ? getPageLocation(page_location) : undefined;

    const objFormatted = {
      ...otherParams,
      central: 'Band SP',
      channel: 'Band SP',
      user_id: user.bandId,
      client_id: user.id,
      page_location: formattedPageLocation,
      platform,
      ...(screenObject || defaultScreenObject),
    };
    otherParams?.season && (objFormatted.season = otherParams.season);
    otherParams?.midia && (objFormatted.midia = otherParams.midia);
    return objFormatted;
  }

  async function sendEventToServer(type: any, data: any) {
    const enableLog = true;
    if (__DEV__ && enableLog) {
      const obj = {
        ...data,
        type,
        date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      };

      // console.log('xxxxxxxxxxxxxxxxxx  xxxxxxxxxxxxxxxxxxxxxx');

      // console.log('Log Event', JSON.stringify(obj, null, 2));
      // console.log('----------------');
      // await fetch('http://localhost:3008/logs', options);
    }
  }

  const logEventAction = (params: ComponentEventType) => {
    const payload = buildComponentEventObject(params);
    sendEventToServer(EventsTypesEnum.ACTION, payload);
    analytics.logEvent(EventsTypesEnum.ACTION, payload);
  };

  const logEventActionName = (params: ComponentEventType, eventName: string) => {
    const payload = buildComponentEventObject(params);
    sendEventToServer(EventsTypesEnum.ACTION, payload);
    analytics.logEvent(eventName, payload);
  };

  const logEventActionWithScreen = (params: ComponentEventType, screenObject: any) => {
    const payload = buildComponentEventObject(params, screenObject);
    sendEventToServer(EventsTypesEnum.ACTION, payload);

    analytics.logEvent(EventsTypesEnum.ACTION, payload);
  };

  const logEventPageView = (params: PageviewType) => {
    const payload = buildPageEventObject(params);
    sendEventToServer(EventsTypesEnum.ACTION, payload);

    analytics.logEvent(EventsTypesEnum.PAGEVIEW, payload);
    dispatch(setScreenAnalytics(payload));
  };

  const logEventPageCleanView = (params: PageviewType) => {
    const payload = buildPageEventObject(params, {});
    sendEventToServer(EventsTypesEnum.ACTION, payload);
    analytics.logEvent(EventsTypesEnum.PAGEVIEW, payload);
    dispatch(setCleanObject(payload));
  };

  async function setUserAppInstanceId() {
    const id = await analytics.getAppInstanceId();
    await analytics.setUserGaId(id ?? '');
    dispatch(setScreenAnalyticsClientId(id));
  }

  return {
    setUserAppInstanceId,
    getPageLocation,
    logEventPageView,
    logEventAction,
    logEventPageCleanView,
    logEventActionWithScreen,
    logEventActionName,
  };
};

export default useFirebaseAnalytics;

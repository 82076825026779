/* eslint-disable import/order */
import { Text, View } from '@gluestack-ui/themed';
import { useIsFocused } from '@react-navigation/native';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import axios from 'axios';
import useHandlerError from 'hooks/useHandlerError';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import * as ReactNative from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import useFirebaseAnalytics from 'services/analytics';
import { backendApiSlice } from 'services/backend';
import { cmsApiSlice, useGetHomeQuery } from 'services/cms';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import DynamicList from './List';

import IsLoading from 'components/isLoading';

export type DynamicRef = {
  refresh: () => Promise<void>;
};

export type DynamicExternalProps = {
  id: string;
  onError?: () => void;
};

export type DynamicInnerProps = {
  useGetHomeData: {
    data?: CmsHome;
    refetch: () => any;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  };
};

const Dynamic = memo(
  forwardRef<DynamicRef, DynamicExternalProps & DynamicInnerProps>((props, ref) => {
    const dispatch = useDispatch();
    const { width: PAGE_WIDTH } = useWindowDimensions();

    const { data, isFetching, error, refetch } = props.useGetHomeData;

    const isWebPageWithCarousel =
      Platform.OS === 'web' &&
      !Platform.isTV &&
      PAGE_WIDTH > 500 &&
      (data?.title === 'Home Principal' || data?.title === 'Assista');

    const handleRefresh = async () => {
      dispatch(cmsApiSlice.util.invalidateTags(['Program', 'Highlights', 'GlobalConfig']));
      dispatch(backendApiSlice.util.invalidateTags(['Watching']));
      refetch();
    };

    useImperativeHandle(ref, () => ({ refresh: handleRefresh }), []);
    useHandlerError(!!error);

    if (!data || isFetching) {
      if (!Platform.isAndroidTV) return null;

      return (
        <View flex={1} justifyContent="center">
          <IsLoading />
        </View>
      );
    }

    return (
      <View flex={1} mt={isWebPageWithCarousel ? -80 : null}>
        {Platform.isTV && data.title === 'Ouça' && (
          <Text
            color="white"
            fontSize={calcSizeForTv(48)}
            lineHeight={calcSizeForTv(60)}
            fontFamily={theme.fonts.inter.bold}
            mb={calcSizeForTv(60)}
            mt={calcSizeForTv(120)}
            pl={calcSizeForTv(24)}>
            {data.title}
          </Text>
        )}
        <DynamicList
          items={data.modules}
          initialNumToRender={Platform.isTV ? 5 : undefined}
          refreshControl={
            <ReactNative.RefreshControl
              colors={['#fff']}
              progressBackgroundColor="#19285e"
              tintColor="#95a4da"
              refreshing={isFetching}
              onRefresh={handleRefresh}
            />
          }
        />
      </View>
    );
  }),
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.useGetHomeData.data === nextProps.useGetHomeData.data &&
      prevProps.useGetHomeData.isFetching === nextProps.useGetHomeData.isFetching &&
      prevProps.useGetHomeData.error === nextProps.useGetHomeData.error
    );
  }
);

const useDynamicComponentAnalytics = (data: any) => {
  const isFocused = useIsFocused();
  const { logEventPageCleanView } = useFirebaseAnalytics();

  useEffect(() => {
    if (data && isFocused) {
      const _UNSAFE_data = data as any;

      const eventObject: any = {
        page_title: data.title ?? 'none',
        user_id: '1234',
        template: 'Home',
        page_location: _UNSAFE_data.id,
      };

      if (_UNSAFE_data.audience) {
        _UNSAFE_data?.audience.map((item: any) => (eventObject[item.key] = item.val));
      }

      logEventPageCleanView(eventObject);
    }
  }, [data, isFocused]);
};

/**
 * NOTA: Sem este shield, o componente terá inumeras re-renderizações por conta do useGetHomeQuery,
 * que atualiza varias propriedades que não utilizamos. Dessa forma, somente quando os
 * campos que nos interessam mudar é que um re-render será causado
 */
const DynamicPerformaticShield = forwardRef<DynamicRef, DynamicExternalProps>((props, ref) => {
  const pollingConfig = { pollingInterval: 900000 };
  const { data, refetch, isFetching, error } = useGetHomeQuery(props.id, pollingConfig);
  const useGetHomeData = { data, refetch, isFetching, error };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  !Platform.isAndroidTV && useDynamicComponentAnalytics(data);

  return <Dynamic ref={ref} useGetHomeData={useGetHomeData as any} {...props} />;
});

export default DynamicPerformaticShield;


import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
    createContext,
    useContext,
    useRef,
    useState,
} from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

type BottomSheetContextProps = {
    children?: React.ReactNode;
    visible: boolean;
    setVisible: (data: boolean) => void;
    bottomSheetRef: any;
    handleToggleSheet: () => void;
    currentUrl: string;
    setCurrentUrl: (data: string) => void;
    menuOptions: any;
    setMenuOptions: (data: string) => void;
    currentMenu: {
        value: number;
        name: string;
        image: string;
    };
    setCurrentMenu: ({ value, name, image }: {
        value: number;
        name: string;
        image: string;
    }) => void;
};

const BottomSheetContext = createContext<BottomSheetContextProps>({} as BottomSheetContextProps);

export const BottomSheetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    const [visible, setVisible] = useState(false);
    const [menuOptions, setMenuOptions] = useState('');
    const [currentMenu, setCurrentMenu] = useState({
        value: 0,
        name: ''
    });
    const [currentUrl, setCurrentUrl] = useState('');

    const handleToggleSheet = () => {
        if (visible) {
            bottomSheetRef.current?.close();
        } else {
            bottomSheetRef.current?.present();
        }

        setVisible(!visible);
    };

    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            <BottomSheetContext.Provider
                value={{
                    visible,
                    setVisible,
                    bottomSheetRef,
                    handleToggleSheet,
                    menuOptions,
                    setMenuOptions,
                    currentMenu,
                    setCurrentMenu,
                    currentUrl,
                    setCurrentUrl,
                }}
            >
                {children}
            </BottomSheetContext.Provider>
        </GestureHandlerRootView>
    );
};

export const useBottomSheet = () => useContext(BottomSheetContext);

export default BottomSheetContext;

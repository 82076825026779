import { Box, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';

import TitleCard from './TitleCard';

import FastImage from 'components/FastImage';

const MediumCard: React.FC<CardProps & { isRelated?: boolean }> = ({
  uri,
  crops,
  title,
  imgTitle,
  reference,
  cardWidth,
  ...rest
}) => {
  const mediumCardAspectRatio = 3 / 4;

  const mediumCardWidth = useBreakpointValue({
    base: 85,
    sm: 106,
    lg: 159,
    xl: calcSizeForDevice(159, rest.isRelated ? 200 : 260),
  });

  const mediumCardHeight = useMemo(
    () => calcHeightWithWidth(cardWidth || mediumCardWidth, mediumCardAspectRatio),
    [cardWidth, mediumCardWidth, mediumCardAspectRatio]
  );

  return (
    <Box width={cardWidth || mediumCardWidth} height={mediumCardHeight} borderRadius={5} {...rest}>
      {uri ? (
        <FastImage
          size="sm"
          aspect="3x4"
          source={{ uri }}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <TitleCard title={title || imgTitle} reference={reference} />
      )}
    </Box>
  );
};

export default withLink(MediumCard, 'MediumCard');

/* eslint-disable import/order */
import { Box } from '@gluestack-ui/themed';
import { useRoute } from '@react-navigation/native';
import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import Carousel, { ICarouselInstance, Pagination } from 'react-native-reanimated-carousel';
import { useSelector } from 'react-redux';
import { useGetHighlightsQuery } from 'services/cms';
import { RootState } from 'store/index';

import CarouselItem from './carouselItem';

import Skeleton from 'components/Skeleton';

const NewCarousel: React.FC<CmsHomeHero> = (props) => {
  const ref = React.useRef<ICarouselInstance>(null);
  const { name } = useRoute();
  const profile = useSelector((state: RootState) => state.profile);
  const { width: PAGE_WIDTH } = useWindowDimensions();
  const progress = useSharedValue<number>(0);

  const { data, isLoading } = useGetHighlightsQuery(props?.searchPrograms, {
    pollingInterval: 900000,
  });

  const onPressPagination = (index: number) => {
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };

  if (isLoading || !data) return <Skeleton variant="hero" />;

  return (
    <>
      {PAGE_WIDTH > 500 ? (
        <Carousel
          ref={ref}
          width={PAGE_WIDTH}
          loop
          style={{ marginBottom: name === 'Home' && !profile.isKid ? -380 : -400 }}
          height={900}
          onProgressChange={progress}
          data={data.items}
          renderItem={({ item, index }: any) => (
            <Box position="relative" h="$full" w="$full">
              <CarouselItem
                item={item}
                currentIndex={ref?.current?.getCurrentIndex()}
                index={index}
              />

              <Pagination.Basic
                progress={progress}
                data={data.items}
                dotStyle={{
                  borderRadius: 7,
                  backgroundColor: '#6386FF',
                  width: 14,
                  height: 14,
                }}
                activeDotStyle={{
                  backgroundColor: 'white',
                }}
                containerStyle={{ position: 'absolute', top: '50%', left: 55, gap: 8 }}
                horizontal
                onPress={onPressPagination}
              />
            </Box>
          )}
        />
      ) : (
        <Box>
          <Carousel
            ref={ref}
            width={PAGE_WIDTH}
            loop
            height={550}
            onProgressChange={progress}
            onConfigurePanGesture={(e) => {
              e.activeOffsetX([-10, 10]);
              e.failOffsetY([-5, 5]);
            }}
            data={data.items}
            renderItem={({ item }: any) => (
              <Box h="$full" w="$full">
                <CarouselItem item={item} />
              </Box>
            )}
          />
          <Pagination.Basic
            progress={progress}
            data={data.items}
            dotStyle={{
              borderRadius: 6,
              backgroundColor: '#6386FF',
              width: 12,
              height: 12,
            }}
            activeDotStyle={{
              backgroundColor: 'white',
            }}
            containerStyle={{
              position: 'absolute',
              bottom: 0,
              height: 100,
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: 5,
              width: '100%',
            }}
            horizontal
          />
        </Box>
      )}
    </>
  );
};

export default NewCarousel;

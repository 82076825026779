import { useRoute } from '@react-navigation/native';
import { Share } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import { useProgram } from './context';

import Button from 'components/Button';
import { useEnvironment } from 'hooks/useEnvironment';

const ProgramShared: React.FC<{ path?: string | undefined }> = ({ path }) => {
  const program = useProgram();
  const route = useRoute();
  const { deeplinkUrl } = useEnvironment();

  const handleShare = async () => {

    if (Platform.OS === 'web') {
      return await navigator.share({
        url: `${deeplinkUrl}${path ?? route.path}`,
        text: `${program.summary}`,
        title: `Assista agora ${program.title}`,
      });
    }
    return await Share.share(
      {
        url: `${deeplinkUrl}${path ?? route.path}`,
        message: `Acesse: ${deeplinkUrl}${path ?? route.path}\n\nDescrição: ${program.summary}`,
        title: `Assista ${program.title}`,
      },
      { subject: `Assista ${program.title}` }
    );
  };

  if (Platform.isTV) {
    return null;
  }

  return (
    <>
      {!Platform.isTV && (
        <Button
          onPress={handleShare}
          variant="ghost"
          size="sm"
          iconType="ShareNodes"
          iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
          textStyle={{ fontSize: calcSizeForDevice(14, 20), marginTop: calcSizeForDevice(10) }}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            paddingHorizontal: 5,
            height: calcSizeForDevice(48, 80),
            marginVertical: calcSizeForDevice(20),
          }}>
          Compartilhar
        </Button>
      )}
    </>
  );
};

export default ProgramShared;

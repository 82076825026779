import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowRotateLeft from './ArrowRotateLeft';
import ArrowRotateRight from './ArrowRotateRight';
import ArrowUp from './ArrowUp';
import AudioLibrary from './AudioLibrary';
import Back10Secs from './Back10Secs';
import Band from './Band';
import BandMore from './BandMore';
import Bell from './Bell';
import Bracket from './Bracket';
import CastClosedCaption from './CastClosedCaption';
import CastPauseSolid from './CastPauseSolid';
import CastXMarkSolid from './CastXMarkSolid';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Circle from './Circle';
import CircleCheck from './CircleCheck';
import CircleExclamation from './CircleExclamation';
import CircleMinus from './CircleMinus';
import CirclePlay from './CirclePlay';
import CirclePlus from './CirclePlus';
import CircleQuestion from './CircleQuestion';
import CircleXmark from './CircleXmark';
import ClapperboardPlay from './ClapperboardPlay';
import ClockRotateLeft from './ClockRotateLeft';
import DeleteLeft from './DeleteLeft';
import DownLeftAndUpRightToCenter from './DownLeftAndUpRightToCenter';
import Envelope from './Envelope';
import FaceFrown from './FaceFrown';
import FaceGrinWide from './FaceGrinWide';
import FaceSmile from './FaceSmile';
import Facebook from './Facebook';
import Foward10Secs from './Foward10Secs';
import Gear from './Gear';
import Ghost from './Ghost';
import HouseWindow from './HouseWindow';
import Instagram from './Instagram';
import Lock from './Lock';
import MagnifyinGlass from './MagnifyingGlass';
import Minus from './Minus';
import Play from './Play';
import PlayerLibrary from './PlayerLibrary';
import Plus from './Plus';
import Popcorn from './Popcorn';
import Radio from './Radio';
import Screencast from './Screencast';
import ShareNodes from './ShareNodes';
import SolidBackwardStep from './SolidBackwardStep';
import SolidCheck from './SolidCheck';
import SolidCircle from './SolidCircle';
import SolidFowardStep from './SolidFowardStep';
import SolidPause from './SolidPause';
import SolidPauseCircular from './SolidPauseCircular';
import SolidPlay from './SolidPlay';
import SolidPlayCircular from './SolidPlayCircular';
import SolidUser from './SolidUser';
import SquareCheck from './SquareCheck';
import SquareEllipsis from './SquareEllipsis';
import SquareMinus from './SquareMinus';
import SquareMusic from './SquareMusic';
import SquarePlus from './SquarePlus';
import SquareXmark from './SquareXmark';
import Streaming from './Streaming';
import Subtitles from './Subtitles';
import Tiktok from './Tiktok';
import Trash from './Trash';
import TriangleExclamation from './TriangleExclamation';
import TurnBack from './TurnBack';
import Tv from './Tv';
import Twitter from './Twitter';
import TwoSliders from './TwoSliders';
import UpRightAndDownLeftFromCenter from './UpRightAndDownLeftFromCenter';
import Video from './Video';
import VideoSlash from './VideoSlash';
import VolumeHigh from './VolumeHigh';
import VolumeSlash from './VolumeSlash';
import Whatsapp from './Whatsapp';
import XTwitter from './XTwitter';
import Xmark from './Xmark';
import Youtube from './Youtube';

type IconMap = { [key in IconsName]: React.FC<SvgProps> };

export const Icons: IconMap = {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowRotateLeft,
  ArrowRotateRight,
  ArrowUp,
  AudioLibrary,
  Back10Secs,
  Band,
  Bell,
  Bracket,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  CircleCheck,
  CircleExclamation,
  CircleMinus,
  CirclePlay,
  CirclePlus,
  CircleQuestion,
  CircleXmark,
  ClapperboardPlay,
  ClockRotateLeft,
  DeleteLeft,
  DownLeftAndUpRightToCenter,
  Envelope,
  Facebook,
  FaceFrown,
  FaceGrinWide,
  FaceSmile,
  Foward10Secs,
  Gear,
  Ghost,
  HouseWindow,
  Instagram,
  Lock,
  MagnifyinGlass,
  Minus,
  Play,
  PlayerLibrary,
  Plus,
  Popcorn,
  Radio,
  Screencast,
  ShareNodes,
  SolidBackwardStep,
  SolidCheck,
  SolidCircle,
  SolidFowardStep,
  SolidPause,
  SolidPauseCircular,
  SolidPlay,
  SolidPlayCircular,
  SolidUser,
  SquareCheck,
  SquareEllipsis,
  SquareMinus,
  SquareMusic,
  SquarePlus,
  SquareXmark,
  Streaming,
  Subtitles,
  Tiktok,
  Trash,
  TriangleExclamation,
  TurnBack,
  Tv,
  Twitter,
  TwoSliders,
  UpRightAndDownLeftFromCenter,
  Video,
  VideoSlash,
  VolumeHigh,
  VolumeSlash,
  Whatsapp,
  Xmark,
  XTwitter,
  Youtube,
  BandMore,
  CastClosedCaption,
  CastPauseSolid,
  CastXMarkSolid,
};

export const getIconByName = (name: IconsName) => {
  return Icons[name] || null;
};

/* eslint-disable import/order */
import {
  CheckIcon,
  CloseIcon,
  Icon,
  Image,
  Menu,
  MenuItem,
  MenuItemLabel,
  Pressable,
  Text,
  Toast,
  ToastDescription,
  ToastTitle,
  VStack,
  useToast,
} from '@gluestack-ui/themed';
import { NavigationProp, useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { HomeTabParamList } from 'navigation/tabs/HomeTab';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFirebaseAnalytics from 'services/analytics';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';
import { RootState } from 'store/index';
import { AuthState } from 'store/slices/authSlice';
import { toggleKidProfile } from 'store/slices/profileSlice';

import AvatarProfile from 'assets/avatar.png';

import { Icons } from 'components/Icons';

type IconProps = typeof Icons.AudioLibrary;

type MenuItemProps = {
  icon: IconProps;
  title?: string;
  hidden?: boolean;
  onPress?: () => void;
};

type MenuAreaProps = {
  userData: AuthState;
  toggleAgeRatingModal: () => void;
  toggleKidProfileModal: () => void;
};

const MenuArea: React.FC<MenuAreaProps> = ({
  userData,
  toggleAgeRatingModal,
  toggleKidProfileModal,
}) => {
  const profile = useSelector((state: RootState) => state.profile);
  const user = useSelector((state: RootState) => state.auth);
  const navigation = useNavigation<NavigationProp<HomeTabParamList>>();
  const { logEventAction } = useFirebaseAnalytics();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const route = useRoute();
  const linkTo = useLinkTo();
  const toast = useToast();

  const MenuOption: MenuItemProps[] = [
    {
      icon: Icons.AudioLibrary,
      hidden: false,
      title: 'Minha lista',
      onPress() {
        logEventAction({ component: 'Menu', reference: 'Minha Lista' });
        linkTo('/favoritos');
      },
    },
    {
      icon: Icons.FaceSmile,
      hidden: profile.isKid || user.isAuthenticated,
      title: 'Entrar',
      onPress() {
        logEventAction({ component: 'Login', reference: 'Login' });
        linkTo('/entrar');
      },
    },
    {
      icon: Icons.FaceSmile,
      hidden: profile.isKid || !user.isAuthenticated,
      title: 'Meu perfil',
      onPress() {
        logEventAction({ component: 'Menu', reference: 'Meu perfil' });
        window.location = 'https://login.band.com.br/' as any;
      },
    },
    {
      icon: Icons.TwoSliders,
      hidden: true,
      title: 'Configuraçãoes',
    },
    {
      icon: Icons.FaceGrinWide,
      title: profile.isKid ? 'Sair de Infantil' : 'Modo Infantil',
      onPress() {
        if (profile.isKid) {
          toggleKidProfileModal();
          return;
        }

        logEventAction({ component: 'Menu', reference: 'Modo infantil' });
        dispatch(toggleKidProfile());
        if (route.name !== 'Home') linkTo('/');
      },
    },
    {
      icon: Icons.TwoSliders,
      hidden: profile.isKid || !user.isAuthenticated,
      title: 'Definir classificação etária',
      onPress: toggleAgeRatingModal,
    },
    {
      icon: Icons.FaceGrinWide,
      hidden: true,
      title: 'Trocar perfil',
    },
    {
      icon: Icons.CircleQuestion,
      hidden: profile.isKid,
      title: 'Ajuda',
      onPress() {
        logEventAction({ component: 'Menu', reference: 'Ajuda' });
        navigation.navigate('FaqSection' as any);
      },
    },
    {
      icon: Icons.CircleXmark,
      hidden: profile.isKid || !user.isAuthenticated,
      title: 'Sair',
      onPress() {
        logEventAction({ component: 'Menu', reference: 'Sair' });
        dispatch(logoutAction());
        dispatch(backendApiSlice.util.resetApiState());

        navigation.navigate('Home');

        toast.show({
          placement: 'top',
          duration: 2000,
          render: ({ id }) => {
            return (
              <Toast bg="#070d31" nativeID={id} borderWidth={1} borderColor="$white">
                <Icon as={CheckIcon} color="$white" mt="$1" mr="$3" />
                <VStack space="xs">
                  <ToastTitle color="$textLight50">Logout efetuado!😊</ToastTitle>
                  <ToastDescription color="$textLight50">
                    Você foi desconectado com sucesso!
                  </ToastDescription>
                </VStack>
                <Pressable mt="$1" onPress={() => toast.close(id)}>
                  <Icon as={CloseIcon} color="$coolGray50" />
                </Pressable>
              </Toast>
            );
          },
        });
      },
    },
  ];

  return (
    <Menu
      onClose={() => setIsOpen(false)}
      onSelectionChange={(keys: any) => {
        const item = MenuOption.find((o) => o.title == keys.currentKey);
        item?.onPress?.();
        setIsOpen(false);
      }}
      mt={8}
      pr={10}
      isOpen={isOpen}
      selectionMode="single"
      placement="bottom right"
      backgroundColor="#15161C"
      disabledKeys={['profilePic']}
      trigger={({ ...triggerProps }) => {
        return (
          <Pressable
            {...triggerProps}
            w={25}
            h={20}
            alignItems="center"
            justifyContent="center"
            aria-label="Mais opçoes do menu"
            onPress={() => {
              setIsOpen(true);
            }}>
            <Image
              h={25}
              w={25}
              rounded="$full"
              borderWidth={2}
              borderColor="#fff"
              source={AvatarProfile}
            />
          </Pressable>
        );
      }}>
      <MenuItem
        disabled
        key="profilePic"
        alignItems="center"
        justifyContent="center"
        sx={{ ':disabled': { opacity: 1, _web: { pointerEvents: 'none' } } }}>
        <VStack alignItems="center">
          <Image
            source={AvatarProfile}
            alt="Avatar Profile"
            size="md"
            borderRadius={2}
            borderWidth={2}
            borderColor="white"
            rounded="$full"
          />
          <Text mt={6} fontSize="$md" fontWeight="semibold" color="$coolGray400">
            {userData.name === '' ? 'Anônimo' : userData.name}
          </Text>
        </VStack>
      </MenuItem>
      {MenuOption.filter((item) => !item.hidden).map((item) => (
        <MenuItem
          marginLeft={10}
          key={item.title}
          textValue={item.title}
          flexDirection="row"
          alignItems="center"
          sx={{ ':focus': { backgroundColor: '#333336' } }}>
          <Icon as={item.icon} size="sm" mr="$2" />
          <MenuItemLabel fontSize="$md" fontWeight="$semibold" color="white">
            {item.title}
          </MenuItemLabel>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MenuArea;

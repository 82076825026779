/* eslint-disable import/order */
import { Box, ButtonText, Text, View, useBreakpointValue } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { AgeRatingKey } from 'utils/ageRating';
import { calcSizeForDevice, getUrlPath } from 'utils/index';
import { Platform } from 'utils/platform';

import CarouselGradient from './carouselGradient';
import CarouselImage from './coverImage';

import NewGradientButton from 'components/Button/NewGradienteButton';
import Rating from 'components/Rating';
import Skeleton from 'components/Skeleton';
import VideoPreview from 'components/VideoPreview';

type CarouselItemProps = {
  item: CmsHighlight;
  index?: number;
  currentIndex?: number;
  loading?: boolean;
  progress?: number;
};

const CarouselItem: React.FC<CarouselItemProps> = ({
  item,
  loading = false,
  currentIndex = 0,
  index,
}) => {
  const linkTo = useLinkTo();
  const redirectLink = getUrlPath(item?.more?.link || '');
  const titleSize = useBreakpointValue({ xl: calcSizeForDevice(48, 54), lg: '$4xl', base: '$3xl' });
  const { width: PAGE_WIDTH } = useWindowDimensions();
  const { isScrollingDown } = useGlobalConfig();
  const [isPreviewAllowed, setIsPreviewAllowed] = React.useState(false);
  const [isPreviewPlaying, setIsPreviewPlaying] = React.useState(false);
  const [watchedVideos, setWatchedVideos] = React.useState<number[]>([]);
  const videoPreviewUrl = item?.videoPreview;

  const descriptionSize = useBreakpointValue({
    xl: calcSizeForDevice(16, 24),
    lg: '$lg',
    base: '$xs',
  });

  React.useEffect(() => {
    setIsPreviewPlaying(false);

    if (watchedVideos.includes(currentIndex)) {
      setIsPreviewAllowed(false);
    } else {
      setIsPreviewAllowed(true);

      const timer = setTimeout(() => {
        setIsPreviewPlaying(true);
        setWatchedVideos((prevWatchedVideos) => [...prevWatchedVideos, currentIndex]);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [currentIndex]);

  React.useEffect(() => {
    if (Platform.OS === 'web' && !Platform.isTV && isScrollingDown) {
      setIsPreviewAllowed(false);
    }
  }, [isScrollingDown]);

  if (loading) return <Skeleton variant="hero" />;

  return (
    <View
      w="$full"
      h="$full"
      position="relative"
      justifyContent={PAGE_WIDTH > 500 ? undefined : 'flex-end'}>
      {item.image?.url && (
        <Box w="$full" h="$full" position="absolute" top="$0" left="$0" zIndex={1}>
          {PAGE_WIDTH > 500 ? (
            videoPreviewUrl && isPreviewAllowed && isPreviewPlaying && currentIndex === index ? (
              <VideoPreview
                videoPreview={videoPreviewUrl}
                isPlaying={isPreviewPlaying}
                setIsPlaying={setIsPreviewPlaying}
                setIsAllowed={setIsPreviewAllowed}
              />
            ) : (
              <CarouselImage image={item.image} />
            )
          ) : (
            <CarouselImage image={item.image} image_vertical={item.image_vertical} />
          )}
          <CarouselGradient />
        </Box>
      )}

      <Box
        mt={PAGE_WIDTH > 500 ? 200 : 0}
        zIndex={3}
        px={PAGE_WIDTH > 500 ? 0 : 20}
        position={PAGE_WIDTH > 500 ? 'absolute' : undefined}
        left={PAGE_WIDTH > 500 ? 50 : 0}
        marginBottom={PAGE_WIDTH > 500 ? null : 120}>
        <Text
          fontSize={titleSize}
          color="$white"
          lineHeight={calcSizeForDevice(64, 60)}
          fontWeight="$semibold"
          mb={PAGE_WIDTH > 500 ? 10 : 0}>
          {item?.title}
        </Text>

        {item?.rating && <Rating ageRatingKey={item.rating as AgeRatingKey} />}

        <Text
          fontSize={descriptionSize}
          lineHeight={calcSizeForDevice(18, 32)}
          my={calcSizeForDevice(20, 15)}
          color="$white">
          {item?.description}
        </Text>

        {item?.more?.title && (
          <Box w={300} mx={PAGE_WIDTH > 500 ? undefined : 'auto'}>
            <NewGradientButton
              style={{ height: 54, width: '100%' }}
              onPress={() => linkTo(redirectLink)}>
              <ButtonText fontWeight="bold" color="#070d31" textTransform="uppercase">
                {item?.more?.title || 'Assistir agora'}
              </ButtonText>
            </NewGradientButton>
          </Box>
        )}
      </Box>
    </View>
  );
};

export default CarouselItem;

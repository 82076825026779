import { getAnalytics, getGoogleAnalyticsClientId, logEvent, setUserId } from 'firebase/analytics';

import { Driver } from './types';

export const useAnalyticsDriver = (): Driver => {
  const analytics = getAnalytics();
  const getAppInstanceIdProxy = async () => getGoogleAnalyticsClientId(analytics);
  const logEventProxy: Driver['logEvent'] = async (name, params) =>
    logEvent(analytics, name, params);

  const setUserGaId = async (id: string) => setUserId(analytics, id);

  return {
    getAppInstanceId: getAppInstanceIdProxy,
    logEvent: logEventProxy,
    setUserGaId,
  };
};

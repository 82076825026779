import { createSlice } from '@reduxjs/toolkit';
import { PageNamesEnum, PageviewType } from 'services/analytics';

const screenAnalyticsSlice = createSlice({
  name: 'sreenAnalytics',
  initialState: {} as PageviewType,
  reducers: {
    setScreenAnalytics: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setScreenAnalyticsUserIds: (state, { payload }) => {
      state.user_id = payload.user_id;
      state.client_id = payload.client_id;
    },
    setScreenAnalyticsClientId: (state, { payload }) => {
      state.client_id = payload;
    },
    setCleanObject: (state, { payload }) => {
      return payload;
    },
  },
});

export const {
  setScreenAnalytics,
  setScreenAnalyticsUserIds,
  setScreenAnalyticsClientId,
  setCleanObject,
} = screenAnalyticsSlice.actions;

export default screenAnalyticsSlice.reducer;

import { View } from '@gluestack-ui/themed';
import { useGlobalConfig } from 'context/GlobalContext';
import React, { ReactElement, useCallback, useState } from 'react';
import { FlatList, ListRenderItem } from 'react-native';
import { calcSizeForDevice } from 'utils/index';

import DynamicListItem from './ListItem';

type DynamicListProps = {
  items: CmsHomeModule[];
  refreshControl?: ReactElement;
  initialNumToRender?: number;
  maxToRenderPerBatch?: number;
  initialNumToLoad?: number;
  maxToLoadPerBatch?: number;
};

const DynamicList: React.FC<DynamicListProps> = ({
  items,
  refreshControl,
  initialNumToRender = 3,
  maxToRenderPerBatch = 3,
  initialNumToLoad = 5,
  maxToLoadPerBatch = 1,
}) => {
  const [end, setEnd] = useState(Math.min(items.length, initialNumToLoad));
  const { handleScroll } = useGlobalConfig();

  const renderItem: ListRenderItem<CmsHomeModule> = ({ item }) => (
    <View marginBottom={calcSizeForDevice(15, 30)}>
      <DynamicListItem {...item} />
    </View>
  );

  const handleEndReached = useCallback(() => {
    if (end < items.length) {
      setEnd(Math.min(items.length, end + maxToLoadPerBatch));
    }
  }, [end, maxToLoadPerBatch]);

  return (
    <FlatList
      refreshControl={refreshControl}
      data={items.slice(0, end)}
      keyExtractor={(item, index) => item._name + index.toString()}
      renderItem={renderItem}
      initialNumToRender={initialNumToRender}
      maxToRenderPerBatch={maxToRenderPerBatch}
      onEndReached={handleEndReached}
      onEndReachedThreshold={1}
      contentContainerStyle={{ paddingBottom: 40 }}
      onScroll={handleScroll}
    />
  );
};

export default DynamicList;

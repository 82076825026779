import { useSocket } from 'context/SocketContext';
import { useEffect } from 'react';

import { usePlayer } from './context';

const PlayerSocketUpdater: React.FC = () => {
  const { socket } = useSocket();
  const { ref: videoRef, isPlaying, isReady, isLive, isShowingAds } = usePlayer();

  useEffect(() => {
    if (!isReady || isLive) {
      return;
    }

    socket.emit(
      isPlaying && !isShowingAds ? 'play' : 'pause',
      videoRef.current?.progress?.().currentTime
    );
  }, [socket, videoRef, isReady, isLive, isPlaying, isShowingAds]);

  return null;
};

export default PlayerSocketUpdater;

import flowplayer, { Flowplayer as FlowplayerType } from '@flowplayer/player';
import { Pressable, Text, View } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import theme from 'theme';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';

import { VideoInfoView } from '../Video/UI/Info';
import { usePlayer } from '../context';

import Button from 'components/Button';
import { Icons } from 'components/Icons';
import { WebDiv } from 'components/WebDiv';

let BROWSING_MENU_TIMER: NodeJS.Timeout;

export function CustomControls(props: { api: FlowplayerType.Player; exit: () => void }) {
  const { isPlaying, media, play, pause, isLive } = usePlayer();
  const flowplayerUI = props.api.parentElement?.querySelector('flowplayer-ui')!;

  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [isBrowsingMenu, setIsBrowsingMenu] = React.useState(true);
  const [isAudioSelectionOpen, setIsAudioSelectionOpen] = React.useState(false);
  const [isSubtitleSelecionOpen, setIsSubtitleSelecionOpen] = React.useState(false);
  const [originalSubtitleElement, setOriginalSubtitleElement] = React.useState<any>(null);

  const sliderRef = React.useRef<HTMLDivElement>(null);
  const TogglePlay = isPlaying ? Icons.SolidPause : Icons.Play;
  const opts = React.useMemo(() => props.api.opts as any, [props.api]);
  const isOtherModalOpen = isAudioSelectionOpen || isSubtitleSelecionOpen;
  const availableAudioTracks = React.useMemo(() => opts.asel.tracks, [opts]);

  const formattedCurrentTime = React.useMemo(
    () => new Date(currentTime * 1000).toISOString().slice(11, 19),
    [currentTime]
  );

  const formattedDuration = React.useMemo(
    () => new Date(duration * 1000).toISOString().slice(11, 19),
    [duration]
  );

  const currentTimePercentage = React.useMemo(
    () => ((currentTime * 100) / duration).toFixed(2),
    [currentTime, duration]
  );

  const availableTextTracks: any[] = React.useMemo(() => {
    const length = props.api.textTracks.length;
    if (!length || duration === 0) return [];

    const allItems = [...Array(length)].map((_v, index) => props.api.textTracks[index]);

    return allItems.reduce((unique, item) => {
      const inArray = unique.find((existentItem: any) => item.id === existentItem.id);
      if (inArray) return unique;
      return [...unique, item];
    }, [] as any);
  }, [props.api.textTracks, duration]);

  const togglePlay = () => {
    props.api.togglePlay();
    if (isPlaying) return pause();
    play();
  };

  const toggleAudioSelection = () => setIsAudioSelectionOpen(!isAudioSelectionOpen);
  const toggleSubtitleSelection = () => setIsSubtitleSelecionOpen(!isSubtitleSelecionOpen);

  const handleMetadataLoaded = React.useCallback(
    () => setDuration(props.api.duration),
    [props.api.duration]
  );

  const handleCurrentTimeUpdate = React.useCallback(
    () => setCurrentTime(props.api.currentTime),
    [props.api.currentTime]
  );

  const handleOnLivePress = () => {
    if (props.api.seekable.end(0)) {
      props.api.currentTime = props.api.seekable.end(0);
      props.api.play();
    }
  };

  const changeSubtitle = (index: number) => {
    try {
      const chosenItem = originalSubtitleElement?.options[index];
      const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
      chosenItem.dispatchEvent(clickEvent);
    } finally {
      setIsSubtitleSelecionOpen(false);
    }
  };

  const changeAudio = (id: string) => {
    const event = new CustomEvent('asel:id', { detail: id });
    props.api.dispatchEvent(event);
    setIsAudioSelectionOpen(false);
  };

  const sliderOnKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      //left
      if (e.keyCode === 37) {
        const newCurrentTime = props.api.currentTime - 15;

        if (newCurrentTime < 0) {
          props.api.currentTime = 0;
          return;
        }

        props.api.currentTime = newCurrentTime;
        return;
      }

      //right
      if (e.keyCode === 39) {
        const newCurrentTime = props.api.currentTime + 15;

        if (newCurrentTime > props.api.duration) {
          props.api.currentTime = props.api.duration;
          return;
        }

        props.api.currentTime = newCurrentTime;
      }
    },
    [props.api]
  );

  const onKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      clearTimeout(BROWSING_MENU_TIMER);
      BROWSING_MENU_TIMER = setTimeout(() => setIsBrowsingMenu(false), 5000);
      if (isPlaying && !isBrowsingMenu) setIsBrowsingMenu(true);
      if (sliderRef.current?.classList.contains('focused')) sliderOnKeyPress(e);
    },
    [isPlaying, isBrowsingMenu, sliderOnKeyPress]
  );

  // reset is browsing
  useEffect(() => {
    BROWSING_MENU_TIMER = setTimeout(() => setIsBrowsingMenu(false), 5000);
    return () => clearTimeout(BROWSING_MENU_TIMER);
  }, []);

  // remove original controls
  useEffect(() => {
    const subtitlesComponent = props.api.parentElement?.querySelector('flowplayer-subtitles-menu')!;
    (subtitlesComponent as any).style.display = 'none';
    setOriginalSubtitleElement(subtitlesComponent);

    props.api.parentElement?.querySelector('flowplayer-header-right-zone')?.remove();
    props.api.parentElement?.querySelector('flowplayer-header-duration')?.remove();
    props.api.parentElement?.querySelector('flowplayer-control-buttons')?.remove();
    props.api.parentElement?.querySelector('flowplayer-live-status')?.remove();
    props.api.parentElement?.querySelector('flowplayer-elapsed-time')?.remove();
    props.api.parentElement?.querySelector('flowplayer-timeline-bar')?.remove();
    props.api.parentElement?.querySelector('flowplayer-control-duration')?.remove();
    props.api.parentElement?.querySelector('flowplayer-volume-control')?.remove();
    props.api.parentElement?.querySelector('flowplayer-quality-menu')?.remove();
    props.api.parentElement?.querySelector('.fp-menu-container')?.remove();

    props.api.on(flowplayer.events.METADATA, handleMetadataLoaded);
    props.api.on(flowplayer.events.TIME_UPDATE, handleCurrentTimeUpdate);

    return () => {
      props.api.off(flowplayer.events.METADATA, handleMetadataLoaded);
      props.api.off(flowplayer.events.TIME_UPDATE, handleCurrentTimeUpdate);
    };
  }, [handleCurrentTimeUpdate, handleMetadataLoaded, props.api]);

  // append keypress listener
  useEffect(() => {
    window.addEventListener('keydown', onKeyPress, false);
    return () => window.removeEventListener('keydown', onKeyPress);
  }, [onKeyPress]);

  // force play when isPlaying flag changes
  useEffect(() => {
    if (isPlaying) {
      props.api.play();
    }
  }, [props.api, isPlaying]);

  if (isPlaying && !isBrowsingMenu) {
    return createPortal(
      <WebDiv className="navigation-container navigation-priority">
        <Pressable role="button" onPress={() => {}} />
      </WebDiv>,
      flowplayerUI
    );
  }

  return createPortal(
    <WebDiv className={`navigation-container ${isOtherModalOpen ? '' : 'navigation-priority'}`}>
      <View zIndex={100} flexDirection="column" top={0} position="absolute" w="$full" h="$full">
        <LinearGradient
          colors={['transparent', '#000000bc']}
          locations={[0.1, 0.4]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          style={{
            padding: calcSizeForTv(16),
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
          }}>
          <Pressable role="button" onPress={props.exit} marginRight={calcSizeForTv(40)}>
            <Icons.TurnBack
              width={calcSizeForDevice(60).toString()}
              height={calcSizeForDevice(60).toString()}
            />
          </Pressable>

          <VideoInfoView {...media!} />
        </LinearGradient>

        <LinearGradient
          colors={['transparent', '#000000bc']}
          locations={[0.1, 0.4]}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          style={{ marginTop: 'auto', height: calcSizeForTv(200) }}>
          <View position="absolute" bottom={0} left={0} w="$full">
            <WebDiv
              ref={sliderRef}
              className={
                isLive
                  ? 'navigation container navigation-ignore'
                  : 'touchable ignore-left-key ignore-right-key'
              }>
              {duration && currentTime && !isLive ? (
                <View m="$4" flexDirection="row" justifyContent="space-between">
                  <Text
                    style={{ color: 'white', fontFamily: theme.fonts.inter.bold, fontSize: 18 }}>
                    {formattedCurrentTime}
                  </Text>

                  <Text
                    style={{ color: 'white', fontFamily: theme.fonts.inter.bold, fontSize: 18 }}>
                    {formattedDuration}
                  </Text>
                </View>
              ) : null}

              <View m="$4" h={5} position="relative" rounded="$full" overflow="hidden">
                <View
                  h={5}
                  zIndex={10}
                  position="absolute"
                  bg={isLive ? theme.colors.error[500] : theme.colors.primary[500]}
                  width={`${isLive ? 100 : currentTimePercentage}%` as any}
                />

                <View
                  h={5}
                  w="$full"
                  zIndex={5}
                  rounded="$full"
                  position="absolute"
                  bg={theme.colors.primary[100]}
                />
              </View>
            </WebDiv>

            <View flexDirection="row" gap="$12" p="$4" mb={calcSizeForTv(10)}>
              <Pressable role="button" onPress={togglePlay}>
                <TogglePlay
                  width={calcSizeForDevice(40).toString()}
                  height={calcSizeForDevice(40).toString()}
                />
              </Pressable>

              {isLive && (!isPlaying || props.api.playerState['is-live-seeked']) && (
                <Button
                  variant="outline"
                  borderColor={theme.colors.error[500]}
                  iconType="SolidCircle"
                  iconStyle={{ color: theme.colors.error[500] }}
                  textStyle={{ marginLeft: calcSizeForTv(10), fontSize: calcSizeForTv(24) }}
                  style={{
                    borderStyle: 'solid',
                    borderWidth: calcSizeForTv(2),
                    borderRadius: calcSizeForTv(10),
                    height: calcSizeForTv(40),
                  }}
                  onPress={() => handleOnLivePress()}>
                  Ao vivo
                </Button>
              )}

              <Pressable role="button" onPress={toggleAudioSelection} ml="auto">
                <Icons.AudioLibrary
                  width={calcSizeForDevice(40).toString()}
                  height={calcSizeForDevice(40).toString()}
                />
              </Pressable>

              {availableTextTracks.length > 0 && (
                <Pressable role="button" onPress={toggleSubtitleSelection}>
                  <Icons.Subtitles
                    width={calcSizeForDevice(40).toString()}
                    height={calcSizeForDevice(40).toString()}
                  />
                </Pressable>
              )}
            </View>
          </View>
        </LinearGradient>
      </View>

      {isAudioSelectionOpen && (
        <View p="$4" zIndex={200} position="absolute" bottom={0} w="$full" bg="rgba(0,0,0,0.8)">
          <WebDiv className="navigation-container navigation-priority">
            <View flexDirection="row" alignItems="center" gap="$4">
              <Pressable role="button" onPress={toggleAudioSelection}>
                <Icons.ArrowLeft
                  width={calcSizeForDevice(40).toString()}
                  height={calcSizeForDevice(40).toString()}
                />
              </Pressable>

              <Text style={{ color: 'white', fontFamily: theme.fonts.inter.bold, fontSize: 18 }}>
                Seleção de áudio
              </Text>
            </View>

            <View flexDirection="row" gap="$4" mt="$4">
              {availableAudioTracks.map((audio: any) => {
                return (
                  <Button variant="solid" role="button" onPress={() => changeAudio(audio.id)}>
                    {audio.title}
                  </Button>
                );
              })}
            </View>
          </WebDiv>
        </View>
      )}

      {isSubtitleSelecionOpen && (
        <View p="$4" zIndex={200} position="absolute" bottom={0} w="$full" bg="rgba(0,0,0,0.8)">
          <WebDiv className="navigation-container navigation-priority">
            <View flexDirection="row" alignItems="center" gap="$4">
              <Pressable role="button" onPress={toggleSubtitleSelection}>
                <Icons.ArrowLeft
                  width={calcSizeForDevice(40).toString()}
                  height={calcSizeForDevice(40).toString()}
                />
              </Pressable>

              <Text style={{ color: 'white', fontFamily: theme.fonts.inter.bold, fontSize: 18 }}>
                Seleção de legenda
              </Text>
            </View>

            <View flexDirection="row" gap="$4" mt="$4">
              {availableTextTracks.map((item, index) => {
                return (
                  <Button role="button" variant="solid" onPress={() => changeSubtitle(index)}>
                    {item.label}
                  </Button>
                );
              })}
            </View>
          </WebDiv>
        </View>
      )}
    </WebDiv>,
    flowplayerUI
  );
}

import {
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
} from 'services/backend';
import { calcSizeForDevice } from 'utils/index';

import { useProgram } from './context';

import Button from 'components/Button';

const ProgramSave: React.FC = () => {
  const program = useProgram();
  const { data } = useGetFavoritesQuery(undefined);

  const findInStorage = data?.items.find((item) => item.programId === program._id);

  const [createFavorite] = useCreateFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  const handleToggleFavorite = () => {
    if (findInStorage) {
      deleteFavorite(findInStorage._id).unwrap();
    } else {
      const favoriteParams = {
        title: program.title,
        url: program.url,
        image: program.imageVertical?.url!,
        programId: program._id,
        kids: !!program.kids,
      };
      createFavorite(favoriteParams).catch((error) => console.log(error));
    }
  };

  return (
    <Button
      useTVFocus
      role="link"
      onPress={handleToggleFavorite}
      variant="ghost"
      size="sm"
      iconType={findInStorage ? 'SquareCheck' : 'SquarePlus'}
      iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
      textStyle={{ fontSize: calcSizeForDevice(14, 20), marginTop: calcSizeForDevice(10) }}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: calcSizeForDevice(5),
        paddingVertical: calcSizeForDevice(10),
        height: calcSizeForDevice(58, 80),
        marginVertical: calcSizeForDevice(20),
      }}>
      Minha lista
    </Button>
  );
};

export default ProgramSave;

import React, { memo, useMemo } from 'react';
import { useLazyGetWatchingListQuery } from 'services/backend';
import { Platform } from 'utils/platform';

import Section from '.';

type SearchWatchingListExternalProps = CmsHomeSection & {
  onLoaded?: () => void;
  waitToRender?: boolean;
  requestTVFocus?: boolean;
};

type SearchWatchingListInternalProps = CmsHomeSection &
  SearchWatchingListExternalProps & {
    useLazyGetWatchingListQueryData: {
      isLoading: boolean;
      fetchData: () => any;
      data?: BackendGenericList<BackendWatching>;
    };
  };

const SearchWatchingList: React.FC<SearchWatchingListInternalProps> = memo(
  (props) => {
    const { data, isLoading } = props.useLazyGetWatchingListQueryData;

    const items = useMemo(() => {
      if (data) {
        return data.items
          .filter((item) => {
            const watchedTime = item.watchedTime ?? 0;
            const duration = item.duration ?? 0;

            if ((watchedTime * 100) / duration < 95) return true;
          })
          .map<CmsCard & { percentageWatched: number }>((item) => {
            const title = item?.title.replaceAll('-', '').replaceAll('/', '-').replaceAll(' ', '-');

            return {
              title: item.title || '',
              image: { url: item.image[3] ?? item.image[1] },
              link: `${item.programUrl}/undefined/${title}/${item?.trackId}`,
              percentageWatched: (item.watchedTime * 100) / (item?.duration ?? 0),
            };
          });
      }

      return [];
    }, [data]);

    React.useEffect(() => {
      if (isLoading) return;
      setTimeout(() => props?.onLoaded?.(), 0);
    }, [isLoading, data]);

    if (Platform.isAndroidTV && (isLoading || props.waitToRender)) {
      return null;
    }

    return <Section {...props} horizontal items={items} loading={isLoading} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.waitToRender === nextProps.waitToRender &&
      prevProps.useLazyGetWatchingListQueryData.data ===
        nextProps.useLazyGetWatchingListQueryData.data &&
      prevProps.useLazyGetWatchingListQueryData.isLoading ===
        nextProps.useLazyGetWatchingListQueryData.isLoading
    );
  }
);

/**
 * NOTA: Sem este shield, o componente terá inumeras re-renderizações por conta do useGetHomeQuery,
 * que atualiza varias propriedades que não utilizamos. Dessa forma, somente quando os
 * campos que nos interessam mudar é que um re-render será causado
 */
const SearchWatchingListPerformaticShield: React.FC<SearchWatchingListExternalProps> = (props) => {
  const [_fetchData, { data, isLoading, isUninitialized }] = useLazyGetWatchingListQuery();
  const fetchData = async () => _fetchData({});

  const composedIsLoading = useMemo(() => {
    return isLoading || isUninitialized;
  }, [isLoading, isUninitialized]);

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <SearchWatchingList
      {...props}
      useLazyGetWatchingListQueryData={{ data, isLoading: composedIsLoading, fetchData }}
    />
  );
};

export default SearchWatchingListPerformaticShield;

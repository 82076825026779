import { Box, Text, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import TitleCard from './TitleCard';

import FastImage from 'components/FastImage';

const BigCard: React.FC<CardProps> = ({
  title,
  tag,
  uri,
  crops,
  jsxSubtitle,
  jsxButton,
  lines,
  ...rest
}) => {
  const bigCardAspectRatio = 9 / 5;

  const bigCardWidth = useBreakpointValue({ base: 222, lg: 333, xl: calcSizeForDevice(333, 550) });
  const cardInfoPadding = useBreakpointValue({ base: 8, lg: 15, xl: calcSizeForDevice(15) });

  const textSize = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(24) : 16,
    lg: 20,
    xl: calcSizeForDevice(20, 24),
  });

  const lineHeight = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(32) : 20,
    lg: 24,
    xl: calcSizeForDevice(24, 32),
  });

  const tagTextSize = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(12) : 10,
    lg: 12,
    xl: calcSizeForDevice(12),
  });

  const bigCardHeight = useMemo(
    () => calcHeightWithWidth(bigCardWidth, bigCardAspectRatio),
    [bigCardWidth, bigCardAspectRatio]
  );

  return (
    <Box width={bigCardWidth} height={bigCardHeight} borderRadius={5} {...rest}>
      {uri ? (
        <>
          <FastImage
            size="md"
            aspect="16x9"
            source={{ uri }}
            style={{ width: '100%', height: '100%' }}
          />

          {(Boolean(title) || Boolean(tag)) && (
            <Box
              width="$full"
              height="$full"
              position="absolute"
              backgroundColor="rgba(0, 0, 0, 0.5)"
              p={cardInfoPadding}>
              {tag && (
                <Box
                  borderWidth={1}
                  borderColor="white"
                  borderRadius={5}
                  px={5}
                  mb={5}
                  alignSelf="flex-start">
                  <Text fontSize={tagTextSize} color="white" fontWeight="600">
                    {tag}
                  </Text>
                </Box>
              )}
              {Boolean(title) && (
                <Text
                  fontSize={textSize}
                  lineHeight={lineHeight}
                  fontWeight="700"
                  color="white"
                  numberOfLines={lines?.title}>
                  {title}
                </Text>
              )}
              {jsxSubtitle}
              {Boolean(jsxButton) && (
                <Box alignSelf="flex-end" mt="auto">
                  {jsxButton}
                </Box>
              )}
              {Boolean(rest?.item?.percentageWatched) && (
                <Box
                  backgroundColor="rgba(217, 217, 217, 0.33)"
                  borderBottomLeftRadius={calcSizeForDevice(3)}
                  borderBottomRightRadius={calcSizeForDevice(3)}
                  w="$full"
                  h={calcSizeForDevice(4)}
                  mt="auto">
                  <Box
                    backgroundColor="rgba(217, 217, 217, 1)"
                    borderBottomLeftRadius={calcSizeForDevice(3)}
                    w={`${rest.item.percentageWatched}%`}
                    h={calcSizeForDevice(4)}
                  />
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <TitleCard title={title || rest?.imgTitle} reference={rest?.reference} />
      )}
    </Box>
  );
};

export default withLink(BigCard, 'BigCard');

import { Platform } from 'utils/platform';

export const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';

export const isMobileWeb = () => {
    const isWeb = Platform.OS === 'web';

    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    return isWeb && isMobileDevice;
}


export const isMobileOrSmallScreen = (width: number) => isMobile || width < 996;

/* eslint-disable import/order */
import { useState } from 'react';
import { Pressable } from 'react-native';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';

type VideoPlayButtonProps = {
  black?: boolean;
};

const VideoPlayButton: React.FC<VideoPlayButtonProps> = ({ black }) => {
  const { play } = usePlayer();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FocusView
      style={{
        borderWidth: 2,
        borderColor: !isFocused ? 'transparent' : 'red',
        borderRadius: 50,
      }}>
      <Pressable
        hasTVPreferredFocus
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onFocus={() => (Platform.isAndroidTV ? setIsFocused(true) : {})}
        onBlur={() => (Platform.isAndroidTV ? setIsFocused(false) : {})}
        onPress={play}>
        {!black ? (
          <Icons.SolidPlayCircular height="50" width="50" />
        ) : (
          <Icons.SolidPlay color="black" />
        )}
      </Pressable>
    </FocusView>
  );
};

export default VideoPlayButton;

import { LinearGradient } from 'expo-linear-gradient';
import { useWindowDimensions } from 'react-native';
import { Platform } from 'utils/platform';

const CarouselGradient: React.FC = () => {
  const { width: SCREEN_WIDTH } = useWindowDimensions();
  return (
    <>
      <LinearGradient
        end={{ x: 0, y: 1 }}
        start={{ x: 0, y: 0 }}
        tvParallaxShiftDistanceX={10}
        colors={['transparent', '#070d31']}
        style={[
          {
            width: '100%',
            height: Platform.OS === 'web' ? (SCREEN_WIDTH < 500 ? '100%' : '40%') : '90%',
            position: 'absolute',
            bottom: 0,
          },
        ]}
      />
      {(Platform.OS === 'web' || Platform.isTV) && SCREEN_WIDTH > 500 && (
        <LinearGradient
          end={{ x: 0, y: 0 }}
          start={{ x: 1, y: 0 }}
          tvParallaxShiftDistanceX={10}
          colors={['transparent', '#070d31']}
          style={[
            {
              width: '50%',
              height: '100%',
              position: 'absolute',
              bottom: 0,
            },
          ]}
        />
      )}
    </>
  );
};

export default CarouselGradient;

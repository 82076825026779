/* eslint-disable import/order */
import { Box, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useSocket } from 'context/SocketContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { useGetMediasQuery } from 'services/cms';
import theme from 'theme';
import { ProgramType } from 'utils/enum';
import { useEnvironment } from 'hooks/useEnvironment';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import Hero from 'components/Hero';
import Player from 'components/Player';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramEpisodes from 'components/Program/Episodes';
import ProgramHeader from 'components/Program/Header';
import ProgramSave from 'components/Program/Save';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';
import { isMobileWeb } from 'utils/deviceInfo';

const SeasonalScreen: React.FC<RootStackScreenProps<'Program'>> = ({ navigation, route }) => {
  const program = useProgram();
  const [actual, setActual] = useState<any>();
  const { data: episodeInfo } = useGetMediasQuery(
    { mediaId: route.params.mediaId },
    { skip: !actual && program?._id == undefined }
  );
  const { data: episodes } = useGetMediasQuery(
    { seasons: actual?._id, program: program?._id, sort: program?.order },
    { skip: !actual && program?._id == undefined }
  );
  const [episode, setEpisode] = useState<CmsApiItem<CmsApiMediaList>>();
  const [selected, setSelected] = useState<string>();
  const [mediaId, setMediaId] = useState<string>();
  const { isPlaying, isFullScreen, isStreaming, isVisible, pause, play, setIsVisible, media } =
    usePlayer();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dropDownSeasonsOrganization, setDropDownSeasonsOrganization] = useState(program?.seasons);
  const [sharePath, setSharePath] = useState<string | undefined>(undefined);
  const { logEventPageView } = useFirebaseAnalytics();
  const isFocused = useIsFocused();
  const [startTime, setStartTime] = useState<number>();
  const { watch } = useSocket();
  const { ads, colors } = useGlobalConfig();
  const { deeplinkUrl } = useEnvironment();

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!isFocused) {
      setMediaId(undefined);
      setEpisode(undefined);
      setSelected(undefined);
    }
  }, [isFocused]);

  const seasonOrder =
    program?.season_order === '-order' || program?.season_order === undefined
      ? program?.seasons?.[0]
      : program?.seasons?.[program?.seasons.length - 1];

  const setEpisodeProxy = (value: any) => {
    if (Platform.isAndroidTV) setIsVisible(true);
    setEpisode(value);
    play();
  };

  useEffect(() => {
    if (route.params.mediaId && episodes) {
      setEpisode(episodes.items.find((e) => e.mediaId == route.params.mediaId));
    }
  }, [episodes, route.params.mediaId]);

  useEffect(() => {
    setIsLoading(true);

    const episodeInfoId = episodeInfo?.items?.[0]?.seasons?.[0]?.id;

    if (route.params.seasonId && episodeInfoId) {
      setSelected(episodeInfoId);
      if (Platform.OS === 'web') {
        const title = episodeInfo?.items?.[0]?.title
          .replaceAll('-', '')
          .replaceAll('/', '-')
          .replaceAll(' ', '-');

        const actualSeason = program?.seasons?.find(
          (item) => episodeInfoId == item.id || episodeInfoId == item._id
        );

        setTimeout(() => {
          window.history.pushState(
            null,
            null,
            `${deeplinkUrl}/${program.id}/${actualSeason.id}/${title}/${route.params.mediaId}`
          );

          setSharePath(`/${program.id}/${actualSeason.id}/${title}/${route.params.mediaId}`);
        }, 500);
      }
    } else if (route.params.seasonId) {
      if (Platform.OS === 'web') {
        window.history.pushState(null, null, `${deeplinkUrl}/${route.params.programId}`);
      } else {
        setSelected(route.params.seasonId);
      }
    } else {
      setSelected(program?.seasons?.[program.seasons.length - 1]?.id);
    }
    setIsLoading(false);
  }, [route.params.mediaId, episodeInfo, program]);

  useEffect(() => {
    if (program?.season_order === 'order') {
      setDropDownSeasonsOrganization([...program.seasons].reverse());
    } else {
      setDropDownSeasonsOrganization(program?.seasons);
    }
  }, []);

  useEffect(() => {
    if (program?.seasons && selected) {
      const actualSeason = program?.seasons?.find(
        (item) => selected == item.id || selected == item._id
      );

      if (!actualSeason) {
        setSelected(seasonOrder?.id);
      }

      setActual(actualSeason);
    }
  }, [program?.seasons, selected]);

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) {
        pause();
      }
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);
      return () => {
        if (!isStreaming) {
          pause();
        }
        setIsActive(false);
      };
    }, [isStreaming])
  );

  useEffect(() => {
    const objLogPage = {
      season: actual?.name,
      page_title: program?.title,
      midia: '',
    };

    episode?.title && (objLogPage.midia = episode?.title);

    if (!!objLogPage.season) {
      logEventPageView(objLogPage);
    }
  }, [program, actual]);

  const playerProps: PlayerProps | undefined = useMemo(() => {
    if (!program) {
      return undefined;
    }

    const objLogPage = {
      season: actual?.name,
      page_title: program?.title,
      midia: '',
    };

    episode?.title && (objLogPage.midia = episode?.title);

    const media: PlayerMedia = {
      title: episode ? episode.title : program.title,
      image: [
        program.imageVertical?.url!,
        program.imageHorizontal?.url!,
        episode?.imageVertical?.url,
        episode?.imageHorizontal?.url,
      ],
      duration: episode ? episode.duration : program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      mediaId: episode?._id,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = episode?.mediaIdDub || program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: episode?.mediaId || program.mediaId });

    return {
      media,
      isLive: program.type == ProgramType.LIVE || program.type == ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
            /iu=([^&]+)/,
            (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
          ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId, episode]);

  useEffect(() => {
    if (!playerProps || playerProps.isLive) {
      return;
    }

    watch(playerProps).then((data) => {
      if (data) {
        setStartTime(data.watchedTime);
      }
    });
  }, [playerProps]);

  const clickWatchObject = {
    component: 'Botão assistir',
    reference: 'Assistir',
    adUnit:
      ads?.disableAds || program.ads?.disableAds
        ? null
        : { name: program.ads?.adunit?.name, value: program.ads?.adunit?.val },
  };

  const Video = useMemo(
    () =>
      mediaId != undefined && isActive && playerProps != undefined ? (
        <Player {...playerProps} startTime={startTime} autoplay />
      ) : undefined,
    [mediaId, isActive, playerProps, startTime]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile !== undefined ? (
        <View style={{ marginTop: isFullScreen ? 0 : isMobileWeb() ? 45 : 80 }}>{Video}</View>
      ) : undefined}
      {(Platform.isAndroidTV || !isFullScreen) && (
        <Hero
          program={program}
          hideCover={mediaId !== undefined}
          numItems={1}
          renderItem={() => (
            <View
              py={calcSizeForDevice(24, 18)}
              backgroundColor={
                Platform.OS !== 'web' && !Platform.isWebTV
                  ? colors || theme.colors.base.darkBlue
                  : width < 1024
                    ? theme.colors.base.darkBlue
                    : ''
              }>
              {!isLoading && (
                <ProgramEpisodes
                  episodes={episodes}
                  seasons={dropDownSeasonsOrganization}
                  setEpisode={setEpisodeProxy}
                  setMediaId={setMediaId}
                  setSelected={setSelected}
                  selected={selected}
                  actual={actual}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
            </View>
          )}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box aspectRatio={1 / 0.56} width={Platform.isTV ? 0 : '80%'}>
                  {Video}
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            <Button
              hasTVPreferredFocus
              role="link"
              useTVFocus
              style={{
                ...(Platform.OS === 'web' || Platform.isTV
                  ? {
                    width: calcSizeForDevice(300, 400),
                    display: mediaId && !Platform.isTV ? 'none' : undefined,
                    height: calcSizeForDevice(40, 52),
                  }
                  : { display: mediaId ? 'none' : undefined }),
              }}
              textStyle={{ fontSize: calcSizeForDevice(18, 24) }}
              iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
              iconType="Play"
              variant="gradient"
              size="md"
              isFullWidth={Platform.OS !== 'web' && !Platform.isTV}
              onPress={() => {
                // logEventAction(clickWatchObject);
                if (mediaId) {
                  if (Platform.isAndroidTV) setIsVisible(true);
                  play();
                  return;
                }

                if (episode) {
                  setMediaId(episode.mediaId);
                } else {
                  setEpisode(episodes?.items[0]);

                  if (actual) {
                    setMediaId(episodes?.items[0].mediaId);
                  } else {
                    setMediaId(program?.mediaId);
                  }
                }

                if (Platform.isAndroidTV) setIsVisible(true);
                play();
              }}>
              {startTime ? 'Continuar assistindo' : 'Assistir'}
            </Button>
            <HStack
              alignItems="center"
              space="md"
              marginTop={calcSizeForDevice(10, 14)}
              marginBottom={calcSizeForDevice(10, 20)}>
              <ProgramSave />
              <ProgramShared path={sharePath} />
            </HStack>
          </Hero.Header>

          <ProgramContent episode={episode} />
        </Hero>
      )}
    </>
  );
};

export default SeasonalScreen;

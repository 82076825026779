import React, { memo } from 'react';

import BigCard from './BigCard';
import MediumBoxedCard from './MediumBoxedCard';
import MediumCard from './MediumCard';
import MidBoxedCard from './MidBoxedCard';
import MidCard from './MidCard';

const Card: React.FC<CardProps & { type: string; isSelected?: boolean }> = ({ type, ...props }) => {
  switch (type) {
    case 'big':
      return <BigCard {...props} />;
    case 'mid':
      return <MidCard {...props} />;
    case 'medium':
      return <MediumCard {...props} />;
    case 'midBoxed':
      return <MidBoxedCard {...props} />;
    case 'mediumBoxed':
      return <MediumBoxedCard {...props} />;
    default:
      return <MediumCard {...props} />;
  }
};

export default memo(Card);

/* eslint-disable import/order */
import { View } from '@gluestack-ui/themed';
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
  createNavigationContainerRef,
} from '@react-navigation/native';
import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { useGlobalConfig } from 'context/GlobalContext';
import { ModalProvider } from 'context/ModalContext';
import { getDeviceTypeAsync } from 'expo-device';
import { HomeTabParamList, MainTabs } from 'navigation/tabs/HomeTab';
import { useEffect } from 'react';
import { Linking } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import CategoryScreen from 'screens/Category';
import TvEasterEggScreen from 'screens/EasterEgg/index.ctv';
import FavoritesScreen from 'screens/Favorites';
import ContactUsScreen from 'screens/Help/ContactUsScreen';
import TvContactUsScreen from 'screens/Help/ContactUsScreen.ctv';
import FAQSectionScreen from 'screens/Help/FAQSectionScreen';
import TvFAQSectionScreen from 'screens/Help/FAQSectionScreen.ctv';
import LegalTermsScreen from 'screens/Help/LegalTermsScreen';
import TvLegalTermsScreen from 'screens/Help/LegalTermsScreen.ctv';
import TvMyProfile from 'screens/Help/MyProfile.ctv';
import LoginScreen from 'screens/Login';
import NotFound from 'screens/NotFound';
import ProgramScreen from 'screens/Program/ProgramScreen';
import Search from 'screens/Search';
import WebViewScreen from 'screens/WebView';
import useFirebaseAnalytics from 'services/analytics';
import AnalyticsScreen from 'services/analytics/Screen';
import { RootState } from 'store/index';
import theme from 'theme';
import { Platform } from 'utils/platform';

import BandMore from 'components/BandMore/BandMoreModal';
import Header from 'components/Header';
import PersistentControl from 'components/Player/Stream/UI/PersistentControl';
import SidebarMenu from 'components/SidebarMenu';
import TvSubMenuArea from 'components/TvSubMenuArea';
import { WebDiv } from 'components/WebDiv';

export type RootStackParamList = {
  Media: undefined;
  Search: undefined;
  Favorites: undefined;
  ContactUs: undefined;
  FaqSection: undefined;
  LegalTerms: undefined;
  VideoPlayer: undefined;
  Category: { slug: string };
  Login: { redirect?: string } | undefined;
  Program: { programId: string; mediaId?: string; seasonId?: string };
  HomeTab: NavigatorScreenParams<HomeTabParamList>;
  MyProfile: undefined;
  EasterEgg: undefined;
  NotFound: { path?: string } | undefined;
  Analytics: undefined;
  TvSubMenuArea: undefined;
  WebView: undefined;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  T
>;

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['bandplay://', 'https://www.bandplay.com'],
  config: {
    screens: {
      HomeTab: {
        screens: {
          Home: '',
          Listen: 'ouca',
          Generic: ':id/h',
          Videos: 'assista',
        },
      },
      Search: 'buscar',
      Favorites: 'favoritos',
      Login: 'entrar',
      Program: ':programId/:seasonId?/:episodeName?/:mediaId?',
      Category: 'categorias/:slug',
      FaqSection: 'ajuda',
      LegalTerms: 'termos',
      TvSubMenuArea: 'tvSubMenuArea',
      ContactUs: 'contato',
      MyProfile: 'perfil',
      EasterEgg: 'easter-egg',
      Analytics: 'analytics',
      NotFound: '*',
    },
  },
};

const Stack = createNativeStackNavigator<RootStackParamList>();
export const navigationRef = createNavigationContainerRef<any>();

export const AppNavigator = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { setUserAppInstanceId } = useFirebaseAnalytics();
  const clientId = useSelector<RootState>((state) => state.screenAnalytics.client_id);
  const { dataLogin, setColor } = useGlobalConfig();

  useEffect(() => {
    if (!clientId) setUserAppInstanceId();
  }, [clientId, setUserAppInstanceId]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.getInitialURL().then((url: any) => {
        getDeviceTypeAsync().then(async (deviceType: any) => {
          const parts = url.split('/');
          const position = parts.slice(0, 3).join('/').length;
          const urlWeb = url.substr(0, position);

          const path = url?.replace(`${urlWeb}/`, '');

          const urlTo = path.indexOf('?') !== -1 ? path.substr(0, path.indexOf('?')) : path;

          if (deviceType === 1 || deviceType === 2) {
            //web
            window.location.href = `bandplay://${urlTo}`;

            // setTimeout(function () {
            //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //     Linking.openURL('https://apps.apple.com/br/app/bandplay/id710693911');
            //   } else if (/android/i.test(userAgent)) {
            //     Linking.openURL('https://play.google.com/store/search?q=bandplay&c=apps');
            //   }
            // }, 1000);
          }
        });
      });
    }
  }, []);

  return (
    <SafeAreaProvider style={{ backgroundColor: theme.colors.base.darkBlue }}>
      <ModalProvider>
        <NavigationContainer
          ref={navigationRef}
          linking={linking}
          theme={{
            ...DefaultTheme,
            colors: { ...DefaultTheme.colors, background: theme.colors.base.darkBlue },
          }}
          onStateChange={(e) => {
            const lastStack = e?.routes[e.routes.length - 1];
            if (lastStack?.name !== 'Program') {
              setColor(undefined);
            }
          }}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <BandMore />
            {Platform.isTV && isAuthenticated && <SidebarMenu />}
            <WebDiv style={{ flex: 1, display: 'flex' }} className="navigation-container">
              <Stack.Navigator screenOptions={{ headerShown: false, animation: 'none' }}>
                {(isAuthenticated ||
                  (Platform.OS === 'web' && !Platform.isTV) ||
                  dataLogin?.skipAuthenticated) && (
                  <>
                    <Stack.Screen name="HomeTab" component={MainTabs} />
                    <Stack.Screen
                      name="Program"
                      component={ProgramScreen}
                      getId={({ params }) => params.programId}
                      options={{
                        headerShown: false,
                      }}
                    />
                    <Stack.Screen
                      name="Category"
                      getId={({ params }) => params?.slug}
                      component={CategoryScreen}
                      options={{
                        header: ({ options }) => <Header title={options.title} showScreenName />,
                        headerShown: !Platform.isTV,
                      }}
                    />
                    <Stack.Screen
                      name="Search"
                      component={Search}
                      options={{
                        title: 'Buscar - Bandplay',
                        header: ({ options }) => <Header title={options.title} showScreenName />,
                        headerShown: !Platform.isTV,
                      }}
                    />
                    <Stack.Screen
                      name="WebView"
                      component={WebViewScreen}
                      options={{
                        title: 'Band +',
                        header: ({ options }) => <Header title={options.title} showScreenName />,
                        headerShown: !Platform.isTV,
                      }}
                    />
                    <Stack.Screen
                      name="Favorites"
                      component={FavoritesScreen}
                      options={{
                        title: 'Minha lista',
                        header: ({ options }) => <Header title={options.title} showScreenName />,
                        headerShown: !Platform.isTV,
                      }}
                    />
                    <Stack.Group
                      screenOptions={{
                        header: () => <Header showScreenName />,
                        headerShown: !Platform.isTV,
                      }}>
                      <Stack.Screen
                        name="FaqSection"
                        component={Platform.isTV ? TvFAQSectionScreen : FAQSectionScreen}
                      />
                      <Stack.Screen
                        name="LegalTerms"
                        component={Platform.isTV ? TvLegalTermsScreen : LegalTermsScreen}
                      />
                      <Stack.Screen
                        name="ContactUs"
                        component={Platform.isTV ? TvContactUsScreen : ContactUsScreen}
                      />
                    </Stack.Group>
                    <Stack.Screen name="MyProfile" component={TvMyProfile} />
                  </>
                )}

                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="NotFound" component={NotFound} />
                {__DEV__ && <Stack.Screen name="Analytics" component={AnalyticsScreen} />}
                {Platform.isTV && <Stack.Screen name="EasterEgg" component={TvEasterEggScreen} />}
                {Platform.isTV && <Stack.Screen name="TvSubMenuArea" component={TvSubMenuArea} />}
              </Stack.Navigator>
            </WebDiv>
          </View>
          <PersistentControl navigationRef={navigationRef} />
        </NavigationContainer>
      </ModalProvider>
    </SafeAreaProvider>
  );
};

/* eslint-disable import/order */
import { Image, Menu, MenuItem, Pressable, Text } from '@gluestack-ui/themed';
import React, { useEffect, useState } from 'react';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';

type MenuDataProps = {
  name: string;
  value: string;
  icon: string;
  image: string;
}

const LiveMenuButton: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentMenu, setCurrentMenu } = useBottomSheet();
  const [menuData, setMenuData] = useState<MenuDataProps[]>([]);
  const { LiveMenu: LiveMenuData } = useGlobalConfig();

  useEffect(() => {
    const customMenu = LiveMenuData?.item.filter(e => !!e.data?.length).map((item: any) => {
      return {
        value: item.id,
        name: item.name,
        icon: item.icon.url,
        image: item.image.url
      }
    }) ?? [];

    setMenuData(customMenu)
  }, []);

  useEffect(() => {
    if (!!menuData.length && !currentMenu.name.length) {
      setCurrentMenu({
        value: 0,
        name: menuData[0]?.name ?? '',
        image: menuData[0]?.image ?? ''
      });
    }
  }, [menuData])

  return (
    <Menu
      onClose={() => setIsOpen(false)}
      onSelectionChange={(keys: any) => {
        const data = menuData.find(key => key.value === keys.currentKey);

        setIsOpen(false);
        setCurrentMenu({
          value: menuData.findIndex(key => key.value === keys.currentKey),
          name: data?.name ?? '',
          image: data?.image ?? '',
        });
      }}
      gap={10}
      mt={20}
      mb={25}
      ml={15}
      pb={10}
      isOpen={isOpen}
      selectionMode="single"
      placement="bottom right"
      backgroundColor="#252836"
      borderWidth={1}
      borderColor='#A8B4FF80'
      disabledKeys={['profilePic']}
      trigger={({ ...triggerProps }) => {
        return (
          <Pressable
            {...triggerProps}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            aria-label="Mais opçoes do menu"
            onPress={() => {
              setIsOpen(true);
            }}>
            {children}
          </Pressable>
        );
      }}>
      {menuData?.map((data, index) => (
        <MenuItem
          paddingHorizontal={10}
          key={data.value}
          flexDirection="row"
          alignItems="center"
          backgroundColor={currentMenu.value === index ? "#2240FF" : "inherit"}
          gap={10}
          textValue={data.name}
          sx={{ ':focus': { backgroundColor: '#333336' } }}>
          <Image
            w={24}
            h={24}
            alt={data.name}
            source={data.icon}
          />
          <Text
            color='$white'
            fontWeight='600'
            fontSize={16}>
            {data.name}
          </Text>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LiveMenuButton;

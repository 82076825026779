import { Image, Pressable, Text, View } from '@gluestack-ui/themed';
import { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import LiveMenuButton from './LiveMenuButton';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { Platform } from 'utils/platform';

const LiveMenu: React.FC = () => {
    const [selected, setSelected] = useState<number | undefined>(undefined);
    const { handleToggleSheet, visible, currentMenu, setCurrentUrl } = useBottomSheet();
    const [menuItems, setMenuItems] = useState<{ name: string, url: string }[]>([]);
    const { LiveMenu: LiveMenuData } = useGlobalConfig();

    useEffect(() => {
        const data = LiveMenuData?.item[currentMenu.value]?.data;

        setMenuItems(data ?? []);
        setSelected(visible ? 0 : undefined);
        setCurrentUrl(data?.[0].url ?? '');
    }, [LiveMenuData, currentMenu]);

    useEffect(() => {
        !visible && setSelected(undefined);
    }, [visible]);

    const renderItem = (item: any, index: number) => (
        <Pressable
            onPress={() => {
                !visible && handleToggleSheet();
                setSelected(index)
                setCurrentUrl(item.url)
            }}
            borderColor='$white'
            borderWidth={1}
            borderRadius={23}
            p={8}
            bg={selected === index ? '$white' : "transparent"}
            alignItems='center'
            justifyContent='center'
            h={32}>
            <Text
                textAlign='center'
                fontSize={12}
                lineHeight={14}
                fontWeight='600'
                color={selected == index ? '#070D31' : '$white'}>
                {item.name}
            </Text>
        </Pressable>
    )

    return (
        <View
            zIndex={3}
            h={Platform.OS !== 'ios' ? 70 : 90}
            backgroundColor="#252836"
            borderTopWidth={1}
            borderTopColor='#A8B4FF80'
            flexDirection='row'>
            <FlatList
                data={menuItems}
                keyExtractor={(_, index) => String(index)}
                ListHeaderComponent={() => (
                    <LiveMenuButton>
                        {
                            currentMenu?.image && <Image
                                source={currentMenu.image}
                                width={44}
                                height={44}
                                alt="Logo do menu"
                            />
                        }
                    </LiveMenuButton>
                )}
                renderItem={({ item, index }) => (
                    renderItem(item, index)
                )}
                contentContainerStyle={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingHorizontal: 20,
                    gap: 12
                }}
                style={{ paddingBottom: Platform.OS !== 'ios' ? 0 : 10 }}
                showsHorizontalScrollIndicator={false}
                horizontal
            />
        </View>
    );
};

export default LiveMenu;

import { useFocusEffect } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import useHandlerError from 'hooks/useHandlerError';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect } from 'react';
import useFirebaseAnalytics, { PageTemplateEnum } from 'services/analytics';
import { useGetProgramQuery } from 'services/cms';
import { ProgramType, programTypeToString } from 'utils/enum';
import { Platform } from 'utils/platform';

import LiveScreen from './LiveScreen';
import MovieScreen from './MovieScreen';
import PodcastScreen from './PodcastScreen';
import TvPodcastScreen from './PodcastScreen.ctv';
import RadioScreen from './RadioScreen';
import SeasonalScreen from './SeasonalScreen';

import FocusView from 'components/FocusView';
import ProgramContext from 'components/Program/context';
import { isEventTimePassed } from 'utils/index';
import TransmitionCatch from 'screens/TransmitionCatch';
import Header from 'components/Header';
import { View } from '@gluestack-ui/themed';

const ProgramScreen: React.FC<RootStackScreenProps<'Program'>> = (props) => {
  const { data: program, error } = useGetProgramQuery(props.route.params.programId);
  const { logEventPageView } = useFirebaseAnalytics();
  const { setColor } = useGlobalConfig();

  useHandlerError(!!error);

  useFocusEffect(
    useCallback(() => {
      setColor(program?.ads?.brandColor);
    }, [program])
  );

  useEffect(() => {
    if (!program) return;
    setColor(program.ads?.brandColor);
    props.navigation.setOptions({ title: program.title });

    let category;

    if (program?.type == ProgramType.RADIO) {
      category = 'Rádio';
    } else {
      if (program?.category) {
        category = program?.category.map((category) => category.name).join(', ');
      } else category = 'null';
    }
    const genre =
      program?.type === ProgramType.LIVE
        ? 'Ao vivo'
        : program?.type === ProgramType.RADIO
          ? 'Rádio'
          : program?.genre?.map((genre) => genre.name).join(', ');

    const eventObject = {
      page_title: program?.title,
      template: PageTemplateEnum.PROGRAM,
      media: program?.type === (ProgramType.LIVE || ProgramType.RADIO) ? 'Ao vivo' : 'On demand',
      type: programTypeToString[program?.type],
      genre,
      program: program?.title,
      director: program?.director,
      cast: program?.cast,
      year: program?.year,
      category,
      page_location: program?.url,
    };

    logEventPageView(eventObject);
  }, [program]);

  if (!program) {
    return null;
  }

  const screens: { [key in CmsProgram['type']]?: React.FC<RootStackScreenProps<'Program'>> } = {
    [ProgramType.LIVE as string]: LiveScreen,
    [ProgramType.MOVIE as string]: MovieScreen,
    [ProgramType.RADIO as string]: RadioScreen,
    [ProgramType.PODCAST as string]: Platform.isTV ? TvPodcastScreen : PodcastScreen,
    [ProgramType.DOCUMENTARY as string]: SeasonalScreen,
    [ProgramType.PROGRAM as string]: SeasonalScreen,
    [ProgramType.TV_SHOW as string]: SeasonalScreen,
  };

  const Screen = screens[program.type];

  if (!Screen) {
    return null;
  }

  const headerNavigation = () => {
    if (Platform.isTV) return <></>;

    return (
      <View position='absolute' top={0} left={0} right={0} zIndex={1}>
        <Header transparent />
      </View>
    )
  }

  const verifyTransmission = () => {
    const eventDateStart = program?.eventDateStart;
    const eventDateEnd = program?.eventDateEnd;

    if (eventDateStart) {
      const dueDate = new Date(eventDateStart);

      if (isEventTimePassed(dueDate)) {
        return <>
          {headerNavigation()}
          <TransmitionCatch startTime={eventDateStart} />
        </>
      }
    }

    return <>
      {headerNavigation()}
      <Screen {...props} />
    </>
  };

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <ProgramContext.Provider value={program}>
        {verifyTransmission()}
      </ProgramContext.Provider>
    </FocusView>
  );
};

export default ProgramScreen;

import { Text, View } from '@gluestack-ui/themed';
import { AgeRatingDefinition, AgeRatingDefinitions, AgeRatingKey } from 'utils/ageRating';
import { calcSizeForDevice } from 'utils/index';

type RatingProps = {
  size?: 'sm' | 'lg';
  customAppendix?: string;
  ageRatingKey: AgeRatingKey;
  append?: keyof Omit<AgeRatingDefinition, 'badge'> | 'custom';
};

const Rating: React.FC<RatingProps> = ({ ageRatingKey, append, customAppendix, size = 'sm' }) => {
  const definition = AgeRatingDefinitions[ageRatingKey];
  const appendValue = append && (append === 'custom' ? customAppendix : definition[append]);

  return (
    <View gap={calcSizeForDevice(10)} display="flex" alignItems="center" flexDirection="row">
      <View
        style={{
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
          width: calcSizeForDevice(size === 'sm' ? 26 : 45, 48),
          height: calcSizeForDevice(size === 'sm' ? 26 : 45, 48),
          backgroundColor: definition.badge.color,
        }}>
        <Text
          color="$white"
          fontSize={calcSizeForDevice(size === 'sm' ? 12 : 16, 18)}
          fontWeight="$bold">
          {definition.badge.label}
        </Text>
      </View>

      {appendValue && (
        <Text color="$white" fontSize={calcSizeForDevice(size === 'sm' ? 12 : 16, 20)}>
          {appendValue}
        </Text>
      )}
    </View>
  );
};

export default Rating;

import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { apiConteudoSlice } from 'services/apiConteudo';
import { backendApiSlice } from 'services/backend';
import { cmsApiSlice } from 'services/cms';
import { mediaApiSlice } from 'services/media';

import authReducer from './slices/authSlice';
import boomarksReducer from './slices/boomarkSlice';
import profileReducer from './slices/profileSlice';
import ratingSlice from './slices/ratingSlice';
import screenAnalyticsReducer from './slices/screenAnalyticsSlice';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['auth', 'profile', 'bookmarks', 'rating'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  bookmarks: boomarksReducer,
  rating: ratingSlice,
  [backendApiSlice.reducerPath]: backendApiSlice.reducer,
  [cmsApiSlice.reducerPath]: cmsApiSlice.reducer,
  [apiConteudoSlice.reducerPath]: apiConteudoSlice.reducer,
  [mediaApiSlice.reducerPath]: mediaApiSlice.reducer,
  screenAnalytics: screenAnalyticsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      backendApiSlice.middleware,
      cmsApiSlice.middleware,
      apiConteudoSlice.middleware,
      mediaApiSlice.middleware
    ),
});

export const persistor = persistStore(store);

import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { Animated, useWindowDimensions } from 'react-native';
import { Platform } from 'utils/platform';

interface HeroImageProps {
  image?: CmsImage;
  image_vertical?: CmsImage;
}

const CarouselImage: React.FC<HeroImageProps> = ({ image, image_vertical }) => {
  const { width: screenWidth } = useWindowDimensions();
  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();
  }, []);

  return (
    <Box h="$full" w={Platform.isAndroidTV ? screenWidth : '$full'}>
      {image_vertical?.url ? (
        <Animated.Image
          style={{ width: '100%', height: '100%', opacity: fadeAnim }}
          alt={image?.title}
          source={{ uri: image_vertical?.url }}
        />
      ) : (
        <Animated.Image
          style={{ width: '100%', height: '100%', opacity: fadeAnim }}
          alt={image?.title}
          source={{ uri: image?.url }}
        />
      )}
    </Box>
  );
};

export default CarouselImage;

import flowplayer, { Flowplayer as FlowplayerType } from '@flowplayer/player';
import '@flowplayer/player/flowplayer.css';
import AdsPlugin from '@flowplayer/player/plugins/ads';
import AirPlayPlugin from '@flowplayer/player/plugins/airplay';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import MessagePlugin from '@flowplayer/player/plugins/message';
import QSelPlugin from '@flowplayer/player/plugins/qsel';
import SubtitlesPlugin from '@flowplayer/player/plugins/subtitles';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import { pt } from '@flowplayer/translations';
import Constants from 'expo-constants';
import userRequired from 'hoc/userRequired';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import useFirebaseAnalytics, {
  ComponentsNameTypesEnum,
  PlayerEventsEnum,
} from 'services/analytics';
import { Platform } from 'utils/platform';

import ASelPlugin from './UI/Flowplayer/ASelPlugin';
import { VideoInfoView } from './UI/Info';

import { usePlayer } from 'components/Player/context';

// idioma
flowplayer.i18n.pt = pt;

// plugins
if (Platform.isTV) {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
} else {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    ChromecastPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
}

const languages: Record<string, string> = {
  dub: 'Dublado',
  origin: 'Original',
  en: 'Inglês',
  pt: 'Português',
  fr: 'Francês',
  es: 'Espanhol',
};

const VideoPlayer = forwardRef<CustomPlayerRef, CustomPlayerProps>((props, ref) => {
  const {
    isLive,
    startTime,
    mediaUrl,
    media,
    trackId,
    adTagUrl,
    setTrack,
    setupTextTracks,
    reloadMedia,
    loadAttempts,
    error,
    play,
    pause,
    setError,
    isVisible,
    setIsVisible,
  } = usePlayer();
  const playerRef = useRef<HTMLDivElement>(null);
  const playerApi = useFlowplayer(playerRef);
  const [showingError, setShowingError] = useState(false);
  const progressRef = useRef<PlayerMediaProgress>({ currentTime: 0 });
  const { logEventActionName } = useFirebaseAnalytics();
  const geoErrorCodes = ['http.403', 'http.live.403'];

  useImperativeHandle(
    ref,
    () => ({
      seek(time: number) {
        playerApi?.fastSeek(time);
      },
      progress(): PlayerMediaProgress {
        return progressRef.current;
      },
      getNativePlayerRef() {
        return playerApi;
      },
    }),
    [playerApi, progressRef]
  );

  useEffect(() => {
    if (!playerApi || !mediaUrl) {
      return;
    }

    playerApi.setSrc(mediaUrl);
    playerApi.setOpts({ start_time: progressRef.current.currentTime || startTime });
    playerApi.play();
  }, [playerApi, mediaUrl, startTime]);

  // re-tentativa de exibição de uma mídia com problema
  useEffect(() => {
    if (!playerApi || !error) return;

    const timer = setTimeout(() => {
      reloadMedia();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [playerApi, error, loadAttempts]);

  // controlador de exibição de erros
  // não é possível esconder/remover uma mensagem em exibição
  useEffect(() => {
    if (!playerApi || !error || showingError) return;

    if (
      Platform.isTV &&
      error.code === 'ERR_NETWORK' &&
      Math.floor(playerApi.buffered.end(0)) > Math.floor(playerApi.currentTime)
    )
      return;

    const timeout = 3000;

    playerApi.setState(flowplayer.states.ERRORED, true);

    playerApi.emit(playerApi.message?.events.SHOW_MESSAGE!, {
      message: error.message,
      timeout,
    });

    setTimeout(() => {
      playerApi.setState(flowplayer.states.ERRORED, false);
      setShowingError(false);
    }, timeout);

    setShowingError(true);
  }, [playerApi, error, showingError]);

  useEffect(() => {
    logEventActionName(
      {
        component: ComponentsNameTypesEnum.PLAYER,
        reference: PlayerEventsEnum.PLAY,
      },
      'view_inicial'
    );
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      const clickListItemWithText = (text: string) => {
        const parentDiv = document.querySelector('.fp-qsel');

        if (parentDiv) {
          const list = parentDiv.querySelector('ol');

          if (list) {
            const listItems = list.getElementsByTagName('li');

            for (let i = 0; i < listItems.length; i++) {
              if (listItems[i].textContent === text) {
                listItems[i].click();
                break;
              }
            }
          }
        }
      }

      setTimeout(() => {
        clickListItemWithText('477p');
      }, 2000)
    }
  }, [isVisible]);

  // inicialização do player
  useEffect(() => {
    if (!playerApi || !media || !trackId) {
      return;
    }

    playerApi.setOpts({
      muted: false,
      live: isLive,
      ima: { ads: [{ time: 0, adTag: adTagUrl }] },
      asel: {
        header: !Platform.isTV ? renderToString(<VideoInfoView {...media} />) : undefined,
        tracks: media?.videoTracks?.map((item) => ({
          id: item.trackId,
          title: languages[item.language],
          selected: item.trackId == trackId,
        })),
      },
    });

    const handleProgress = (event: FlowplayerType.FPEvent<unknown>) => {
      progressRef.current.currentTime = (event.target as any).currentTime;
    };

    const handleAudioChange = (event: FlowplayerType.FPEvent<unknown>) => {
      const i = media?.videoTracks.findIndex((x) => x.trackId == event.detail);
      if (i != undefined) {
        setTrack(i);
      }
    };

    const handleError = (event: FlowplayerType.FPEvent<unknown>) => {
      event.preventDefault();
      const hasGeoError = geoErrorCodes.includes((event?.detail as any)?.code);
      setError({
        code: 'error',
        message:
          !Platform.isTV || (Platform.isTV && hasGeoError)
            ? `Este vídeo não está disponível para a sua região\n ou não está sendo transmitido no momento.`
            : '',
      });
    };

    const handlePlay = () => play();
    const handlePause = () => pause();

    const handleEnded = () => {
      const flowplayerDiv = document.querySelectorAll('.fp-fullscreen-exit')[0] as HTMLHtmlElement;

      if (flowplayerDiv) {
        flowplayerDiv.click();
      }

      if (isVisible) {
        setIsVisible(false);
      }
    };

    setupTextTracks([]);

    playerApi.on('asel:id', handleAudioChange);
    playerApi.on(flowplayer.events.ERROR, handleError);
    playerApi.on(flowplayer.events.PROGRESS, handleProgress);
    playerApi.on(flowplayer.events.PLAY, handlePlay);
    playerApi.on(flowplayer.events.PAUSE, handlePause);
    playerApi.on(flowplayer.events.ENDED, handleEnded);

    return () => {
      playerApi.off('asel:id', handleAudioChange);
      playerApi.off(flowplayer.events.ERROR, handleError);
      playerApi.off(flowplayer.events.PROGRESS, handleProgress);
      playerApi.off(flowplayer.events.PLAY, handlePlay);
      playerApi.off(flowplayer.events.PAUSE, handlePause);
      playerApi.off(flowplayer.events.ENDED, handleEnded);
    };
  }, [playerApi, media, trackId, play, pause, setupTextTracks]);

  return (
    <Flowplayer
      ref={playerRef}
      src={undefined}
      token={Constants.expoConfig?.extra?.flowPlayerToken}
      opts={{
        lang: 'pt',
        hls: {
          liveSyncDurationCount: 5,
          liveMaxLatencyDurationCount: 20,
        },
      }}
    />
  );
});

VideoPlayer.displayName = 'VideoPlayer';

export default userRequired(VideoPlayer);

import { useEffect, useState } from "react";

const useEnvironment = () => {
    const [deeplinkUrl, setDeeplinkUrl] = useState('');

    useEffect(() => {
        const path = window.location?.href ?? 'https://bandplay.com/';
        const parts = path.split('/');
        const position = parts.slice(0, 3).join('/').length;
        const baseUrl = path.substr(0, position);

        setDeeplinkUrl(baseUrl);
    }, [])

    return { deeplinkUrl };
}

export { useEnvironment };
/* eslint-disable import/order */
import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { FlatList } from 'react-native';
import { Platform } from 'utils/platform';

import { useHorizontalPagination } from '../helpers/pagination';
import SectionArrows from './SectionArrows';
import SectionHorizontalSkeleton from './SectionHorizontalSkeleton';

import FocusView from 'components/FocusView';

type SectionHorizontalProps = CmsHomeSection & {
  onEndReached?: () => void;
  requestTVFocus?: boolean;
};

const SectionHorizontal: React.FC<SectionHorizontalProps> = (props) => {
  const { ref, onScroll, goForward, renderItem, goBackward, canGoForward, canGoBackward } =
    useHorizontalPagination({
      renderItem: props.renderItem,
      items: props.items,
    });

  if (props.loading) {
    return <SectionHorizontalSkeleton {...props} />;
  }

  if (!props.items || !props.items.length) {
    return null;
  }

  const list = (
    <FocusView>
      <FlatList
        ref={ref}
        focusable={false}
        data={props.items}
        onScroll={onScroll}
        maxToRenderPerBatch={3}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        horizontal={props.variant !== 'section.boxed'}
        keyExtractor={(item, index) => `${item.link}_${index.toString()}`}
      />
    </FocusView>
  );

  return (
    <Box position="relative" focusable={false}>
      {list}

      {!Platform.isTV && (
        <SectionArrows side="left" onPress={goBackward} isVisible={canGoBackward} />
      )}

      {!Platform.isTV && (
        <SectionArrows side="right" onPress={goForward} isVisible={canGoForward} />
      )}
    </Box>
  );
};

export default SectionHorizontal;

import { LiveSheet } from './LiveSheet';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import LiveMenu from './LiveMenu';
import { getDeviceTypeAsync } from 'expo-device';
import { useEffect, useState } from 'react';
import { useGlobalConfig } from 'context/GlobalContext';

const LiveMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isSmallDevice, setIsMobile] = useState(false);
    const { LiveMenu: LiveMenuData } = useGlobalConfig();

    useEffect(() => {
        getDeviceTypeAsync().then(async (deviceType: any) => {
            setIsMobile(deviceType === 1 || deviceType === 2);
        });
    }, []);

    if (!isSmallDevice || !LiveMenuData) {
        return children;
    }

    return (
        <>
            <BottomSheetModalProvider>
                {children}
                <LiveSheet />
            </BottomSheetModalProvider>
            <LiveMenu />
        </>
    );
};

export default LiveMenuProvider;

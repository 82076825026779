/* eslint-disable import/order */
import { BottomTabScreenProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { useModalConfig } from 'context/ModalContext';
import { RootStackParamList, RootStackScreenProps } from 'navigation/AppNavigator';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import HomeScreen from 'screens/Home/HomeScreen';
import WebViewScreen from 'screens/WebView';
import { RootState } from 'store/index';
import theme from 'theme';
import { Platform } from 'utils/platform';

import Header from 'components/Header';
import TabBarButton from 'components/TabBarButton';

export type HomeTabParamList = {
  Home: { id?: string } | undefined;
  Videos: { id?: string };
  Listen: { id?: string };
  Generic: { id?: string };
  BandMore: { id?: string };
};

export type HomeTabScreenProps<T extends keyof HomeTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<HomeTabParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

const Tab = createBottomTabNavigator<HomeTabParamList>();

export const MainTabs: React.FC = () => {
  const { width } = useWindowDimensions();
  const profile = useSelector((state: RootState) => state.profile);
  const { toggleModal, isModalVisible } = useModalConfig();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        header: ({ options }) => <Header title={options.title} showScreenName />,
        headerShown: !Platform.isTV,
        headerTransparent: false,
        tabBarStyle: {
          borderTopWidth: 0,
          backgroundColor: '#252836',
          height: Platform.OS === 'ios' ? 90 : 70,
          padding: Platform.OS === 'ios' ? 10 : 0,
          display:
            profile.isKid || (Platform.OS === 'web' && width > 479) || Platform.isTV
              ? 'none'
              : 'flex',
        },
        tabBarShowLabel: false,
        tabBarHideOnKeyboard: true,
        tabBarActiveTintColor: theme.colors.base.white,
        tabBarInactiveTintColor: theme.colors.base.gray,
      }}>
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        getId={({ params }) => params?.id}
        options={{
          title: 'Bandplay',
          tabBarIcon: (p) => <TabBarButton title="Início" onFocus={p.focused} icon="home" />,
        }}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            if (isModalVisible) {
              e.preventDefault();
            }
          },
        })}
      />
      <Tab.Screen
        name="Videos"
        component={HomeScreen}
        initialParams={{ id: 'assista' }}
        getId={({ params }) => params?.id}
        options={{
          title: 'Assista - Bandplay',
          tabBarIcon: (p) => <TabBarButton title="Assista" onFocus={p.focused} icon="video" />,
        }}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            if (isModalVisible) {
              e.preventDefault();
            }
          },
        })}
      />
      <Tab.Screen
        name="Listen"
        component={HomeScreen}
        initialParams={{ id: 'ouca' }}
        getId={({ params }) => params?.id}
        options={{
          title: 'Ouça - Bandplay',
          tabBarIcon: (p) => <TabBarButton title="Ouça" onFocus={p.focused} icon="radio" />,
        }}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            if (isModalVisible) {
              e.preventDefault();
            }
          },
        })}
      />

      <Tab.Screen
        name="BandMore"
        component={WebViewScreen}
        initialParams={{ id: '' }}
        options={{
          title: 'Band',
          tabBarIcon: (p) => <TabBarButton title="Band" onFocus={p.focused} icon="bandmore" />,
        }}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            e.preventDefault();
            toggleModal();
          },
        })}
      />
      <Tab.Screen
        name="Generic"
        component={HomeScreen}
        getId={({ params }) => params?.id}
        options={{
          tabBarStyle: { display: 'none' },
          tabBarButton: () => null,
        }}
      />
    </Tab.Navigator>
  );
};

import NetInfo from '@react-native-community/netinfo';
import { ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { useDispatch } from 'react-redux';
import { cmsApiSlice, useGetGlobalConfigQuery } from 'services/cms';

type GlobalContextProps = GlobalState & {
  setColor: (payload: any) => void;
};

type GlobalState = Partial<GlobalConfig> & {
  dataIsLoading: boolean;
  hasInternet?: boolean;
  dataLogin?: any;
  colors?: any;
  isScrollingDown?: boolean;
  handleScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
};

type GlobalAction =
  | { type: 'SET_DATA_LOGIN'; payload: Pick<GlobalState, 'LoginScreen'> }
  | {
      type: 'SET_DATA_HEADER';
      payload: Pick<GlobalState, 'HeaderMenu'>;
    }
  | {
      type: 'SET_DATA_GENERAL';
      payload: any;
    }
  | {
      type: 'SET_DATA_ISLOADING';
      payload: boolean;
    }
  | {
      type: 'SET_DATA';
      payload?: GlobalConfig;
    }
  | { type: 'HAS_INTERNET'; payload: boolean | undefined }
  | {
      type: 'SET_COLORS';
      payload?: any;
    };

function reducer(state: GlobalState, action: GlobalAction) {
  switch (action.type) {
    case 'SET_DATA_HEADER':
      return { ...state, dataHeader: action.payload };
    case 'SET_DATA_LOGIN':
      return { ...state, dataLogin: action.payload };
    case 'SET_DATA_GENERAL':
      return { ...state, dataGeneral: action.payload };
    case 'SET_DATA_ISLOADING':
      return { ...state, dataIsLoading: action.payload };
    case 'SET_DATA':
      return { ...state, ...action.payload, dataIsLoading: false };
    case 'HAS_INTERNET':
      return { ...state, hasInternet: action.payload };
    case 'SET_COLORS':
      return { ...state, colors: action.payload, dataIsLoading: false };
    default:
      return state;
  }
}

const GlobalContext = createContext({} as GlobalContextProps);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data, isLoading } = useGetGlobalConfigQuery();
  const reduxDispatch = useDispatch();

  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentOffsetY = event.nativeEvent.contentOffset.y;
    const contentHeight = event.nativeEvent.contentSize.height;
    const viewportHeight = event.nativeEvent.layoutMeasurement.height;

    const tenPercentHeight = (contentHeight - viewportHeight) * 0.1;

    if (currentOffsetY >= tenPercentHeight) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    dataIsLoading: true,
  });

  const setColor = (payload: any) => {
    dispatch({ type: 'SET_COLORS', payload });
  };

  useEffect(() => {
    return NetInfo.addEventListener(({ isConnected }) => {
      if (state.hasInternet === false && isConnected) {
        reduxDispatch(cmsApiSlice.util.resetApiState());
      }
      dispatch({ type: 'HAS_INTERNET', payload: isConnected ?? undefined });
    });
  }, [state.hasInternet]);

  useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: data });
  }, [data, isLoading]);

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        setColor,
        handleScroll,
        isScrollingDown,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalConfig = () => useContext(GlobalContext);
